<!--
Usage:

Vue.use(vuetiful, [
    'HeadMeta'
]);
HeadMetaOptions.setOption('titleOd', 'Title on destroy');
HeadMetaOptions.setOption('ods', 'reset');

<head-meta title="Awesome Page Title"
           titleOd="Site Name"
           description="Description w/o OD."></clv-head-meta>

It will display the first not null string.
<head-meta title="[loading === true ? $t('Loading') : null, instanceBaseName, myGlobalMethod('not found msg'), 'Cloutlayer']"
           titleOd="Site Name"
           description="Description w/o OD."></clv-head-meta>

// Nice idea!
myGlobalMethod(message) {
    if (message === null) return null;

    return this.$(message) + ' ' + this.siteTitleSuffix;
}

props:
    - url
    - ogLocale
    - lang
    - image
    - keywords
    - description
    - title
    - head
    - html
    - ods : On Destroy Strategy
        - 'config_or_reset' : if OD value is missing,
           check if exists in options (!== null && !== undefined) or reset (empty string).
        - 'disable' : Disables ODS and excludes everything.
        - 'reset' : if OD value is missing, reset (empty string).
        - 'config_or_exclude' : if OD value is missing,
           check if exists in options (!== null && !== undefined) or exclude.

Props overrides values from config.

'exclude' explained : Once an element's attr, or text is set,
that value is being preserved even after 'destroy/deactivate'.
It will change only if another instance of HeadMeta is 'mounted/activated'
and has value for that prop or has an OD strategy that can affect that prop.
-->
<script>
import { headfulProps, handler, odHandler } from './props-helper';

/**
 * Head Meta (aka Metadata).
 *
 * Updates Document Head metadata (e.g.: title, description, OG, e.t.c.).
 *
 * NOTICE : Mount this component once and only if data are ready to be displayed.
 *
 * @author Dimitris Gkoulis
 * @since 24 March 2020
 */
export default {
    name: 'HeadMeta',
    props: headfulProps,
    activated () {
        // required for keep-alive components https://vuejs.org/v2/api/#keep-alive
        handler(this.$props);
    },
    deactivated () {
        // required for keep-alive components https://vuejs.org/v2/api/#keep-alive
        odHandler(this.$props);
    },
    mounted () {
        // Listen for changes, and on each change process the props.
        this.$watch('$props', handler, { deep: true, immediate: true });
    },
    beforeUnmount () {
        odHandler(this.$props);
    },
    render () {
        return null;
    }
};
</script>
