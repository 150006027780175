<template>
    <label
        v-if="!!label"
        :for="name"
        class="VvLabel"
    >
        {{ label }} <span class="text-xs font-normal text-gray-400" v-if="optional">{{ optionalText }}</span>
    </label>
    <div
        class="space-y-2"
        :class="{ 'mt-1': !!label }"
    >
        <div
            v-for="(item, index) in list"
            :key="item.rKey + '_' + reactivityKey"
        >
            <div class="flex flex-row space-x-2">
                <Button
                    variation="white"
                    @click="moveListItemUp(item)"
                    :disabled="index === 0"
                >
                    <span class="sr-only">{{ $t('Move up') }}</span>
                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7l4-4m0 0l4 4m-4-4v18" />
                    </svg>
                </Button>
                <Button
                    variation="white"
                    @click="moveListItemDown(item)"
                    :disabled="index >= (list.length - 1)"
                >
                    <span class="sr-only">{{ $t('Move down') }}</span>
                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 17l-4 4m0 0l-4-4m4 4V3" />
                    </svg>
                </Button>
                <Field
                    :name="name + '[' + index + ']'"
                    :label="label + ' #' + (index + 1)"
                    :value="item.value"
                    @input="handleListItemInput($event, item)"
                    :validate-on-blur="validateOnBlur"
                    :validate-on-change="validateOnChange"
                    :validate-on-input="validateOnInput"
                    :validate-on-model-update="validateOnModelUpdate"
                    :validate-on-mount="true"
                    v-slot="{ field }"
                >
                    <input
                        :key="reactivityKey"
                        :id="name + '[' + index + ']'"
                        :tabindex="(tabIndex + ((index+1)/100))"
                        v-bind="field"
                        :type="type"
                        class="VvListInputText--Default"
                    />
                </Field>
                <Button
                    variation="white"
                    @click="removeListItem(item)"
                >
                    {{ $t('common_action_remove') }}
                </Button>
            </div>
            <div>
                <ErrorMessage
                    :name="name + '[' + index + ']'"
                    v-slot="{ message }"
                >
                    <div class="mt-2 mb-6">
                        <p class="VvErrorMessage__Text">{{ message }}</p>
                    </div>
                </ErrorMessage>
            </div>
        </div>
        <Button
            variation="white"
            @click="addNewListItem"
        >
            <svg class="w-5 h-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            {{ $t('common_action_add') }}
        </Button>
    </div>
    <div class="mt-2">
        <ErrorMessage
            :name="name"
            v-slot="{ message }"
        >
            <p class="VvErrorMessage__Text">{{ message }}</p>
        </ErrorMessage>
    </div>
    <div class="mt-2">
        <p v-if="!!helpText" class="VvHelpText--Default">{{ helpText }}</p>
    </div>
</template>
<script>
import {inject, onBeforeMount, ref, toRefs} from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { Field, ErrorMessage, useField } from 'vee-validate';
import { I18nPlugin } from '@/core/plugins';
import { EventBusService } from '@/core/service';
import { RandomUtils } from '@/core/utils';

const i18n = I18nPlugin.getDefaultI18nInstance();

/**
 * List of text inputs pattern based on VeeValidate.
 *
 * NOTICE: This component cannot be used outside of SchemaDefinitionAsyncForm yet.
 * It requires dynamic management of validation object for each list element.
 * @future Check if it's meaningful to move all SchemaDefinitionAsyncForm-depended
 * components to a specific directory.
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 February 2021
 */
export default {
    name: 'VvListInputText',
    inheritAttrs: false,
    components: {
        Field,
        ErrorMessage
    },
    emits: [
        'update:modelValue',
        'update-batch'
    ],
    props: {
        name: {
            type: String,
            required: true
        },
        label: String,
        optional: Boolean,
        optionalText: {
            type: String,
            default: function () {
                return i18n.global.t('Optional');
            }
        },
        helpText: String,
        modelValue: null,
        type: {
            type: String,
            default: 'text'
        },
        tabIndex: Number,
        // FormVueLate + VeeValidate specifics.
        validateOnBlur: {
            type: Boolean,
            default: true
        },
        validateOnChange: {
            type: Boolean,
            default: true
        },
        validateOnInput: {
            type: Boolean,
            default: true
        },
        validateOnModelUpdate: {
            type: Boolean,
            default: true
        },
        validation: {
            type: Object,
            default: () => ({})
        }
    },
    setup (props, { emit }) {
        const { name, label, modelValue, validations } = toRefs(props);
        let initialValue = modelValue ? modelValue.value : []; // Expected to be array.
        if (!Array.isArray(initialValue)) initialValue = []; // Auto-correct. (highly unexpected).
        const list = ref(initialValue.map(function (val) {
            return {
                value: val,
                rKey: RandomUtils.getUniqueId()
            };
        }));
        const getListLastIndex = () => (list.value.length - 1);

        // This field instance manages the list field (not the list elements).
        const {
            handleChange
        } = useField(name, validations, {
            initialValue: initialValue,
            label: label,
            type: Array
        });

        const randomName = RandomUtils.getUniqueId('random-name-');
        const formName = inject('formName', randomName); // It may be missing.
        const sectionName = inject('sectionName', randomName); // It may be missing.
        const eventKeyForListField = `${formName}:${sectionName}:update-list-field-validation`;
        // num: the index to add or remove.
        // action: add|remove
        const notifySdAsyncFormSectionForUpdate = (num, action) => {
            EventBusService.publish(eventKeyForListField, {
                name: name.value,
                num: num,
                action: action
            });
        };

        // Hacky but necessary. Be careful. It can cause serious performance issues.
        const reactivityKey = ref(0);

        // Cleans and emits data.
        const handleAnyChange = () => {
            // realList is immutable.
            const realList = list.value
                .map(function (i) {
                    return i.value;
                });
            handleChange(realList);
            emit('update:modelValue', realList);
        };

        const addNewListItem = () => {
            list.value.push({
                rKey: RandomUtils.getUniqueId(),
                value: ''
            });
            notifySdAsyncFormSectionForUpdate(getListLastIndex(), 'add');
            handleAnyChange();
            reactivityKey.value++;
        };
        const removeListItem = (itemToRemove) => {
            const index = list.value.findIndex(function (item) {
                return item.rKey === itemToRemove.rKey;
            });
            if (index === -1) return;
            const lastElIndex = getListLastIndex(); // Call before removal!
            list.value.splice(index, 1);
            notifySdAsyncFormSectionForUpdate(lastElIndex, 'remove');
            handleAnyChange();
            reactivityKey.value++;
        };

        const handleListItemInput = ($event, item) => {
            item.value = $event.target.value;
            handleAnyChange();
        };

        const moveListItemUp = (itemToMove) => {
            const index = list.value.findIndex(function (item) {
                return item.rKey === itemToMove.rKey;
            });
            if (index === -1) return;
            if (index === 0) return;
            const temporary = list.value.splice(index, 1)[0]; // remove `from` item and store it
            list.value.splice((index - 1), 0, cloneDeep(temporary)); // insert stored item into position `to`
            handleAnyChange();
            reactivityKey.value++;
        };
        const moveListItemDown = (itemToMove) => {
            const index = list.value.findIndex(function (item) {
                return item.rKey === itemToMove.rKey;
            });
            if (index === -1) return;
            if (index === (list.value.length - 1)) return;
            const temporary = list.value.splice(index, 1)[0]; // remove `from` item and store it
            list.value.splice((index + 1), 0, cloneDeep(temporary)); // insert stored item into position `to`
            handleAnyChange();
            reactivityKey.value++;
        };

        onBeforeMount(() => {
            // Create validation objects for initial list.
            for (let i=0; i<list.value.length; i++) {
                notifySdAsyncFormSectionForUpdate(i, 'add');
            }
        });

        return {
            reactivityKey,
            list,
            addNewListItem,
            removeListItem,
            handleListItemInput,
            moveListItemUp,
            moveListItemDown
        };
    }
};
</script>

<style>
.VvListInputText--Default {
    @apply shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md;
    @apply text-lg;
    @apply dark:bg-gray-700 dark:border-gray-700 dark:text-gray-100;
    @apply dark:focus:ring-gray-500 dark:focus:border-gray-500;
}
</style>
