<template>
    <div class="relative text-center">
        <p class="py-8 text-gray-600 font-medium">{{ $t('Not Found') }}</p>
    </div>
</template>

<script>
/**
 * Common Not Found (404) pattern.
 * It should be used when a resource is missing from a dynamic route.
 *
 * @author Dimitris Gkoulis
 * @createdAt 20 January 2021
 * @lastModifiedAt 24 January 2021
 */
export default {
    name: 'CommonNotFound'
};
</script>

<i18n>
{
    "en": {
        "Not Found": "Not Found"
    },
    "el": {
        "Not Found": "Δε Βρέθηκε"
    }
}
</i18n>
