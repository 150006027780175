/****************************************************************************************************
 * MS Websites "ContentGroup" resource API Client.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 11 April 2021
 * @lastModifiedAt Saturday 11 December 2021
 ****************************************************************************************************/

import ApiUrlProvider from '../api-url-provider';
import BaseApiClient from '../base-api-client';

const WebsitesContentGroupBasicApiClient = {
    createContentGroup (data) {
        const url = ApiUrlProvider.kernelVersionedApi('content-groups');
        return BaseApiClient.post(url, data);
    },
    updateContentGroup (data) {
        const url = ApiUrlProvider.kernelVersionedApi('content-groups');
        return BaseApiClient.put(url, data);
    },
    getContentGroup (id) {
        const url = ApiUrlProvider.kernelVersionedApi('content-groups/' + id);
        return BaseApiClient.get(url);
    },
    deleteContentGroup (id) {
        const url = ApiUrlProvider.kernelVersionedApi('content-groups/' + id);
        return BaseApiClient.delete(url);
    },
    getAllContentGroups (page = 0, size = 10, sortField = 'id', sortDirection = 'desc') {
        const url = ApiUrlProvider.kernelVersionedApi('content-groups');
        return BaseApiClient.get(url, {
            params: {
                page: page,
                size: size,
                sort: sortField + ',' + sortDirection
            }
        });
    }
};

export default WebsitesContentGroupBasicApiClient;
