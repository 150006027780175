export default {};

// Authorities Constants.
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_CLTLR_PREFIX = 'ROLE_CLTLR_';
export const ROLE_CLTLR_SUFFIX = '_TENANT';
export const ROLE_CLTLR_USER_SUFFIX = '_TENANT_USER';
export const ROLE_CLTLR_ADMIN_SUFFIX = '_TENANT_ADMIN';

// PPA (Polyglot Persistence API) Constants.
export const PPA_COMMON_NAME_REGEX = /^[a-zA-Z][a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*$/;
