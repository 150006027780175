/****************************************************************************************************
 * MS Websites "Website" resource API Client.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 12 January 2021
 * @lastModifiedAt Saturday 11 December 2021
 ****************************************************************************************************/

import ApiUrlProvider from '../api-url-provider';
import BaseApiClient from '../base-api-client';

const WebsitesWebsiteBasicApiClient = {
    createWebsite (data) {
        const url = ApiUrlProvider.kernelVersionedApi('websites');
        return BaseApiClient.post(url, data);
    },
    updateWebsite (data) {
        const url = ApiUrlProvider.kernelVersionedApi('websites');
        return BaseApiClient.put(url, data);
    },
    getWebsiteById (id) {
        const url = ApiUrlProvider.kernelVersionedApi('websites/' + id);
        return BaseApiClient.get(url);
    },
    deleteWebsiteById (id) {
        const url = ApiUrlProvider.kernelVersionedApi('websites/' + id);
        return BaseApiClient.delete(url);
    },
    getAllWebsites (page = 0, size = 10, sortField = 'id', sortDirection = 'desc') {
        const url = ApiUrlProvider.kernelVersionedApi('websites');
        return BaseApiClient.get(url, {
            params: {
                page: page,
                size: size,
                sort: sortField + ',' + sortDirection
            }
        });
    }
};

export default WebsitesWebsiteBasicApiClient;
