/****************************************************************************************************
 * Event Bus service.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 5 February 2021
 ****************************************************************************************************/

import mitt from 'mitt';

const emitter = mitt();

const EventBusService = {};

EventBusService.publish = function (key, payload = null) {
    if (typeof key !== 'string') return;
    emitter.emit(key, payload);
};

EventBusService.subscribe = function (key, handler) {
    emitter.on(key, handler);
};

EventBusService.unsubscribe = function (key, handler) {
    emitter.off(key, handler);
};

export default EventBusService;
