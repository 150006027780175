<template>
    <label
        v-if="!!label"
        :for="name"
        class="VvLabel"
    >
        {{ label }} <span class="text-xs font-normal text-gray-400" v-if="optional">{{ optionalText }}</span>
    </label>
    <div :class="{ 'mt-1': !!label, 'VvInputText-Wrapper--Default': !suffix, 'VvInputText-Wrapper--Suffix': !!suffix }">
        <Field
            :name="name"
            :label="label"
            :type="type"
            :value="modelValue"
            @input="onInput"
            :validate-on-blur="validateOnBlur"
            :validate-on-change="validateOnChange"
            :validate-on-input="validateOnInput"
            :validate-on-model-update="validateOnModelUpdate"
            v-slot="{ field }"
        >
            <input
                v-bind="field"
                :type="type"
                :id="name"
                :tabindex="tabIndex"
                :class="{ 'VvInputText--Default': !suffix, 'VvInputText--Suffix': !!suffix }"
            />
            <span v-if="!!suffix" class="VvInputText__Suffix">{{ suffix }}</span>
        </Field>
    </div>
    <div class="mt-2">
        <ErrorMessage
            :name="name"
            v-slot="{ message }"
        >
            <p class="VvErrorMessage__Text">{{ message }}</p>
        </ErrorMessage>
    </div>
    <div class="mt-2">
        <p v-if="!!helpText" class="VvHelpText--Default">{{ helpText }}</p>
    </div>
</template>
<script>
import { Field, ErrorMessage } from 'vee-validate';
import { I18nPlugin } from '@/core/plugins';

const i18n = I18nPlugin.getDefaultI18nInstance();

/**
 * Text input pattern based on VeeValidate.
 *
 * @author Dimitris Gkoulis
 * @createdAt 15 January 2021
 * @lastModifiedAt 24 February 2021
 */
export default {
    name: 'VvInputText',
    inheritAttrs: false,
    components: {
        Field,
        ErrorMessage
    },
    emits: [
        'update:modelValue',
        'update-batch'
    ],
    props: {
        name: {
            type: String,
            required: true
        },
        label: String,
        optional: Boolean,
        optionalText: {
            type: String,
            default: function () {
                return i18n.global.t('Optional');
            }
        },
        helpText: String,
        suffix: String,
        modelValue: null,
        type: {
            type: String,
            default: 'text'
        },
        tabIndex: Number,
        // FormVueLate + VeeValidate specifics.
        validateOnBlur: {
            type: Boolean,
            default: true
        },
        validateOnChange: {
            type: Boolean,
            default: true
        },
        validateOnInput: {
            type: Boolean,
            default: true
        },
        validateOnModelUpdate: {
            type: Boolean,
            default: true
        },
        validation: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        onInput (event) {
            if (event.target.value === null || event.target.value === undefined) {
                this.$emit('update:modelValue', null);
                return;
            }

            if (this.type === 'number') {
                this.$emit('update:modelValue', Number(event.target.value));
            } else if (this.type === 'integer' || this.type === 'long') {
                this.$emit('update:modelValue', parseInt(event.target.value, 10));
            } else if (this.type === 'decimal' || this.type === 'double') {
                this.$emit('update:modelValue', parseFloat(event.target.value));
            } else {
                this.$emit('update:modelValue', event.target.value);
            }
        }
    }
};
</script>

<style>
.VvInputText-Wrapper--Default {
}
.VvInputText-Wrapper--Suffix {
    @apply flex rounded-md shadow-sm;
}

.VvInputText--Default {
    @apply shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md;
    @apply text-lg;
    @apply dark:bg-gray-700 dark:border-gray-700 dark:text-gray-100;
    @apply dark:focus:ring-gray-500 dark:focus:border-gray-500;
}
.VvInputText--Suffix {
    @apply flex-1 focus:ring-blue-500 focus:border-blue-500 focus-within:z-10 block w-full min-w-0 rounded-none rounded-l-md sm:text-sm border-gray-300;
    @apply text-lg;
    @apply dark:bg-gray-700 dark:border-gray-700 dark:text-gray-100;
    @apply dark:focus:ring-gray-500 dark:focus:border-gray-500;
}

.VvInputText__Suffix {
    @apply inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500;
    @apply dark:bg-gray-500 dark:border-gray-500 dark:text-gray-100;
}
</style>
