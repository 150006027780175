/****************************************************************************************************
 * Provides functionality to mock requests.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 12 January 2021
 * @lastModifiedAt Saturday 11 December 2021
 ****************************************************************************************************/

import MockAdapter from 'axios-mock-adapter';
import ApiUrlProvider from './api-url-provider';

let mockAdapter = null;

// eslint-disable-next-line
const ErrorResponseDataMock = {
    type: '#',
    title: 'Internal Server Error',
    message: 'error.http.500',
    detail: 'Please contact support ASAP!',
    path: '/',
    params: {},
    timestamp: 1
};

const ApiMocks = {
    /** Must called before any mock registration. */
    enableMocks (axios) {
        mockAdapter = new MockAdapter(axios, { delayResponse: 2000 });
    },

    createWebsiteMock () {
        const status = 201;
        const url = ApiUrlProvider.kernelVersionedApi('websites');
        let data = {
            'id': '6001ff9d1f96c26dd890954e',
            'uuid': '26861a31-c468-454b-b5fb-9827cd5fc7f0',
            'alias': 'test',
            'title': 'Test Website',
            'description': '',
            'environment': 'DEVELOPMENT',
            'mode': 'PUBLISHED',
            'debug': true,
            'themeId': '6001ff9d1f96c26dd8909551',
            'settings': {
                'timeZone': 'UTC'
            }
        };
        // data = ErrorResponseDataMock;
        const headers = {};
        mockAdapter.onPost(url).reply(status, data, headers);
    },
    getAllWebsitesMock () {
        const status = 200;
        const url = ApiUrlProvider.kernelVersionedApi('websites');
        let data = [
            {
                id: '1',
                title: 'Dimitris Gkoulis Personal Website',
                alias: 'gkoulis'
            },
            {
                id: '2',
                title: 'DGK Software House',
                alias: 'dgk'
            },
            {
                id: '3',
                title: 'Open Source - DGK Software House',
                alias: 'dgk-open-source'
            }
        ];
        // data = [];
        const headers = {
            'x-total-count': data.length,
            'x-collection-count': data.length
        };
        mockAdapter.onGet(url).reply(status, data, headers);
    }
};

export default ApiMocks;
