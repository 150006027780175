<template>
    <Spinner/>
</template>

<script>
import Spinner from '../Spinner/Spinner';

/**
 * Common loading.
 *
 * @author Dimitris Gkoulis
 * @createdAt 13 January 2021
 */
export default {
    name: 'CommonLoading',
    components: {
        Spinner
    }
};
</script>
