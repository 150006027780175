/****************************************************************************************************
 * MS Websites "Domain" resource API Client.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 22 January 2021
 * @lastModifiedAt Saturday 11 December 2021
 ****************************************************************************************************/

import ApiUrlProvider from '../api-url-provider';
import BaseApiClient from '../base-api-client';

const WebsitesDomainBasicApiClient = {
    createDomain (data) {
        const url = ApiUrlProvider.kernelVersionedApi('domains');
        return BaseApiClient.post(url, data);
    },
    updateDomain (data) {
        const url = ApiUrlProvider.kernelVersionedApi('domains');
        return BaseApiClient.put(url, data);
    },
    getDomainById (id) {
        const url = ApiUrlProvider.kernelVersionedApi('domains/' + id);
        return BaseApiClient.get(url);
    },
    deleteDomain (id) {
        const url = ApiUrlProvider.kernelVersionedApi('domains/' + id);
        return BaseApiClient.delete(url);
    },
    getAllDomains (websiteId = null, page = 0, size = 10, sortField = 'id', sortDirection = 'desc') {
        const url = ApiUrlProvider.kernelVersionedApi('domains');
        return BaseApiClient.get(url, {
            params: {
                websiteId: websiteId,
                page: page,
                size: size,
                sort: sortField + ',' + sortDirection
            }
        });
    }
};

export default WebsitesDomainBasicApiClient;
