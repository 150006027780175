/****************************************************************************************************
 * UserAccount basic API Client.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 8 April 2021
 * @lastModifiedAt Saturday 11 December 2021
 ****************************************************************************************************/

import ApiUrlProvider from '../api-url-provider';
import BaseApiClient from '../base-api-client';

const UserAccountBasicApiClient = {
    getUser () {
        const url = ApiUrlProvider.kernelVersionedApi('user-accounts/whoami');
        return BaseApiClient.get(url);
    }
};

export default UserAccountBasicApiClient;
