/****************************************************************************************************
 * MS Websites "Theme" resource API Client.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 18 January 2021
 * @lastModifiedAt Saturday 11 December 2021
 ****************************************************************************************************/

import ApiUrlProvider from '../api-url-provider';
import BaseApiClient from '../base-api-client';

const WebsitesThemeBasicApiClient = {
    createTheme (data) {
        const url = ApiUrlProvider.kernelVersionedApi('themes');
        return BaseApiClient.post(url, data);
    },
    getAllThemes (page = 0, size = 10, sortField = 'id', sortDirection = 'desc') {
        const url = ApiUrlProvider.kernelVersionedApi('themes');
        return BaseApiClient.get(url, {
            params: {
                page: page,
                size: size,
                sort: sortField + ',' + sortDirection
            }
        });
    },
    getThemeById (id) {
        const url = ApiUrlProvider.kernelVersionedApi('themes/' + id);
        return BaseApiClient.get(url);
    },
    deleteTheme (id) {
        const url = ApiUrlProvider.kernelVersionedApi('themes/' + id);
        return BaseApiClient.delete(url);
    },

    deployTheme (id, file, fileName = null) {
        let formData = new FormData();
        if (typeof fileName !== 'string') {
            fileName = file.name;
        }
        formData.append('file', file);
        const url = ApiUrlProvider.kernelVersionedApi('themes/' + id + '/deployments');
        return BaseApiClient.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
                // ...formData.getHeaders()
            },
            params: {
                replaceExisting: true,
                deleteMissing: false,
                fileExtension: 'html'
            },
            withCredentials: true,
            crossDomain: true
        });
    },
    getThemeDeployment (id) {
        const url = ApiUrlProvider.kernelVersionedApi('themes/' + id + '/deployments');
        return BaseApiClient.get(url);
    },

    deployThemeAssets (id, file, fileName = null) {
        let formData = new FormData();
        if (typeof fileName !== 'string') {
            fileName = file.name;
        }
        formData.append('file', file);
        const url = ApiUrlProvider.kernelVersionedApi('themes/' + id + '/assets-deployments');
        return BaseApiClient.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
                // ...formData.getHeaders()
            },
            withCredentials: true,
            crossDomain: true
        });
    },
    getThemeAssetsDeployment (id) {
        const url = ApiUrlProvider.kernelVersionedApi('themes/' + id + '/assets-deployments');
        return BaseApiClient.get(url);
    }
};

export default WebsitesThemeBasicApiClient;
