/****************************************************************************************************
 * MS Websites "PageDatum" resource API Client.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 April 2021
 * @lastModifiedAt Saturday 11 December 2021
 ****************************************************************************************************/

import ApiUrlProvider from '../api-url-provider';
import BaseApiClient from '../base-api-client';

const WebsitesPageDatumBasicApiClient = {
    createPageDatum (data) {
        const url = ApiUrlProvider.kernelVersionedApi('datums');
        return BaseApiClient.post(url, data);
    },
    updatePageDatum (data) {
        const url = ApiUrlProvider.kernelVersionedApi('datums');
        return BaseApiClient.put(url, data);
    },
    getPageDatum (id) {
        const url = ApiUrlProvider.kernelVersionedApi('datums/' + id);
        return BaseApiClient.get(url);
    },
    deletePageDatum (id) {
        const url = ApiUrlProvider.kernelVersionedApi('datums/' + id);
        return BaseApiClient.delete(url);
    },
    getAllPageDatums (
        scopes = [],
        system = null,
        page = 0,
        size = 10,
        sortField = 'id',
        sortDirection = 'desc'
    ) {
        const url = ApiUrlProvider.kernelVersionedApi('datums');
        return BaseApiClient.get(url, {
            params: {
                scopes: scopes,
                system: system,
                page: page,
                size: size,
                sort: sortField + ',' + sortDirection
            }
        });
    }
};

export default WebsitesPageDatumBasicApiClient;
