/****************************************************************************************************
 * Provides utilities that compute states based on real-world scenarios.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 12 January 2021
 ****************************************************************************************************/

function fetchingInProgress (fetching) {
    if (typeof fetching === 'boolean') {
        return fetching;
    }
    return !!fetching;
}

function listingHasData (data) {
    if (typeof data == 'number') {
        return data > 0;
    } else if (Array.isArray(data)) {
        return data.length > 0;
    } else {
        return false;
    }
}

function hasError (error) {
    if (error === null || error === undefined) {
        return false;
    }
    return !!error;
}

const StateUtils = {
    displayListing (fetching, data, error) {
        return fetchingInProgress(fetching) === false && listingHasData(data) === true && hasError(error) === false;
    },
    displayListingEmptyState (fetching, data, error) {
        return fetchingInProgress(fetching) === false && listingHasData(data) === false && hasError(error) === false;
    },
    displayListingFirstUseEmptyState (fetching, data, error) {
        return fetchingInProgress(fetching) === false && listingHasData(data) === false && hasError(error) === false;
    },
    displayListingError (fetching, error) {
        return fetchingInProgress(fetching) === false && hasError(error) === true;
    }
};

export default StateUtils;
