/****************************************************************************************************
 * Provides functionality to build Cloutlayer Global CDN URLs.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 24 February 2021
 ****************************************************************************************************/

const GlobalCdnUrlProvider = {};

GlobalCdnUrlProvider.thumbFromUrl = function (url, thumb) {
    return url.replace('/o/', ('/t/' + thumb + '/'));
};

export default GlobalCdnUrlProvider;
