/****************************************************************************************************
 * Manages Vue i18n plugin.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 30 January 2021
 * @lastModifiedAt 8 February 2021
 ****************************************************************************************************/

import { createI18n } from 'vue-i18n';

import {
    SILENT_TRANSLATION_WARNINGS,
    SILENT_FALLBACK_WARNINGS
} from '../environment';

const en = {};
const el = {};

const defaultI18NInstance = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en: en,
        el: el
    },
    // Legacy API.
    silentTranslationWarn: SILENT_TRANSLATION_WARNINGS,
    silentFallbackWarn: SILENT_FALLBACK_WARNINGS,
    warnHtmlInMessage: 'off',
    // New API.
    warnHtmlMessage: false
});

const I18nPlugin = {
    getDefaultI18nInstance () {
        return defaultI18NInstance;
    }
};

export default I18nPlugin;
