/****************************************************************************************************
 * Provides functionality to build Cloutlayer API URLs.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 12 January 2021
 * @lastModifiedAt Saturday 11 December 2021
 ****************************************************************************************************/

import {
    CLOUTLAYER_KERNEL_BASE_URL,
    CLOUTLAYER_KERNEL_API_VERSION
} from '../environment';

function cleanPath (path) {
    if (typeof path !== 'string') {
        throw new Error('path must be a String!');
    }
    path = path.trim();
    if (path.startsWith('/')) {
        return path.substring(1);
    } else {
        return path;
    }
}

const ApiUrlProvider = {
    kernel (path) {
        return CLOUTLAYER_KERNEL_BASE_URL + '/' + cleanPath(path);
    },
    kernelVersionedApi (path) {
        return CLOUTLAYER_KERNEL_BASE_URL + '/api/' + CLOUTLAYER_KERNEL_API_VERSION + '/' + cleanPath(path);
    }
};

export default ApiUrlProvider;
