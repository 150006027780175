<template>
    <div class="relative inline-block text-left">
        <Menu>
            <MenuButton
                :class="variationClass">
                <span>{{ label }}</span>
                <svg class="w-5 h-5 ml-2 -mr-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                </svg>
            </MenuButton>
            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0">
                <MenuItems class="absolute z-10 left-0 md:left-auto md:right-0 w-44 mt-1 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
                    <slot></slot>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script>
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';

const PRIMARY_CLASS = 'inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500';
const SECONDARY_CLASS = 'inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500';
const WHITE_CLASS   = 'inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500';

/**
 * Common Dropdown.
 *
 * @author Dimitris Gkoulis
 * @createdAt 30 January 2021
 * @lastModifiedAt 15 April 2021
 */
export default {
    name: 'CommonDropdown',
    components: {
        Menu,
        MenuButton,
        MenuItems
    },
    props: {
        label: String,
        variation: {
            type: String,
            default: 'primary',
            validator: function (value) {
                return ['primary', 'secondary', 'white'].indexOf(value) !== -1;
            },
            required: false
        }
    },
    computed: {
        variationClass () {
            let classesLiteral = '';
            if (this.variation === 'primary') {
                classesLiteral = PRIMARY_CLASS;
            } else if (this.variation === 'secondary') {
                classesLiteral = SECONDARY_CLASS;
            } else if (this.variation === 'white') {
                classesLiteral = WHITE_CLASS;
            } else {
                classesLiteral = PRIMARY_CLASS; // Unreachable.
            }
            return classesLiteral;
        }
    }
};
</script>

<style>
.CommonDropdown-Link--Default {
    @apply block px-4 py-2 text-sm font-medium text-blue-600 hover:bg-gray-100 hover:text-blue-900 cursor-pointer;
}
.CommonDropdown-Link--Danger {
    @apply block px-4 py-2 text-sm font-medium text-red-600 hover:bg-gray-100 hover:text-gray-900 cursor-pointer;
}
</style>
