<template>
    <button
        :type="type"
        :class="variationClass"
        :disabled="disableButton">
        <Spin :color-class="spinColorClass" v-if="loading"/>
        <slot></slot>
    </button>
</template>

<script>
import Spin from './Spin';

const PRIMARY_CLASS = 'relative inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black';
const ACCENT1_CLASS = 'relative inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500';
const WHITE_CLASS   = 'relative inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500';
const DANGER_CLASS  = 'relative inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500';

/**
 * A Button.
 *
 * @author Dimitris Gkoulis
 * @createdAt 12 January 2021
 * @lastModifiedAt 22 February 2021
 */
export default {
    name: 'Button',
    components: {
        Spin
    },
    props: {
        type: {
            type: String,
            default: 'button',
            validator: function (value) {
                return ['button', 'submit'].indexOf(value) !== -1;
            },
            required: false
        },
        variation: {
            type: String,
            default: 'primary',
            validator: function (value) {
                return ['primary', 'accent1', 'white', 'danger'].indexOf(value) !== -1;
            },
            required: false
        },
        loading: {
            type: Boolean,
            default: false,
            required: false
        },
        disableOnLoading: {
            type: Boolean,
            default: true,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    computed: {
        variationClass () {
            let extraClasses = '';
            if (this.disabled === true) {
                extraClasses = ' opacity-70 cursor-not-allowed';
            } else if (this.loading === true && this.disableOnLoading === true) {
                extraClasses = ' opacity-70 cursor-not-allowed';
            }

            let classesLiteral = '';
            if (this.variation === 'primary') {
                classesLiteral = PRIMARY_CLASS;
            } else if (this.variation === 'accent1') {
                classesLiteral = ACCENT1_CLASS;
            } else if (this.variation === 'white') {
                classesLiteral = WHITE_CLASS;
            } else if (this.variation === 'danger') {
                classesLiteral = DANGER_CLASS;
            } else {
                classesLiteral = PRIMARY_CLASS; // Unreachable.
            }

            return classesLiteral + extraClasses;
        },
        spinColorClass () {
            if (this.variation === 'primary') {
                return 'text-white';
            } else if (this.variation === 'accent1') {
                return 'text-white';
            } else if (this.variation === 'white') {
                return 'text-grey-600';
            } else {
                return 'text-white'; // Unreachable.
            }
        },
        disableButton () {
            if (this.disabled === true) return true;
            // noinspection RedundantIfStatementJS
            if (this.disableOnLoading === true && this.loading === true) return true;
            return false;
        }
    }
};
</script>
