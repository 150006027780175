<template>
    <div class="flex items-center">
        <Field
            :name="name"
            :label="label"
            :value="modelValue"
            @input="onInput"
            :validate-on-blur="validateOnBlur"
            :validate-on-change="validateOnChange"
            :validate-on-input="validateOnInput"
            :validate-on-model-update="validateOnModelUpdate"
            v-slot="{ field }"
        >
            <input
                v-bind="field"
                :value="modelValue"
                :checked="checked"
                type="checkbox"
                :true-value="true"
                :false-value="false"
                :id="name"
                :tabindex="tabIndex"
                class="VvCheckboxBinary--Default"
            />
        </Field>
        <label
            v-if="!!label"
            :for="name"
            class="VvLabel ml-2"
        >
            {{ label }} <span class="text-xs font-normal text-gray-400" v-if="optional">{{ optionalText }}</span>
        </label>
    </div>
    <div class="mt-2">
        <ErrorMessage
            :name="name"
            v-slot="{ message }"
        >
            <p class="VvErrorMessage__Text">{{ message }}</p>
        </ErrorMessage>
    </div>
    <div class="mt-2">
        <p v-if="!!helpText" class="VvHelpText--Default">{{ helpText }}</p>
    </div>
</template>
<script>
import { Field, ErrorMessage } from 'vee-validate';
import { I18nPlugin } from '@/core/plugins';

const i18n = I18nPlugin.getDefaultI18nInstance();

/**
 * Binary checkbox input pattern based on VeeValidate.
 *
 * @author Dimitris Gkoulis
 * @createdAt 5 February 2021
 * @lastModifiedAt 19 February 2021
 */
export default {
    name: 'VvCheckboxBinary',
    inheritAttrs: false,
    components: {
        Field,
        ErrorMessage
    },
    emits: [
        'update:modelValue',
        'update-batch'
    ],
    props: {
        name: {
            type: String,
            required: true
        },
        label: String,
        optional: Boolean,
        optionalText: {
            type: String,
            default: function () {
                return i18n.global.t('Optional');
            }
        },
        helpText: String,
        modelValue: null,
        tabIndex: Number,
        // FormVueLate + VeeValidate specifics.
        validateOnBlur: {
            type: Boolean,
            default: true
        },
        validateOnChange: {
            type: Boolean,
            default: true
        },
        validateOnInput: {
            type: Boolean,
            default: true
        },
        validateOnModelUpdate: {
            type: Boolean,
            default: true
        },
        validation: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        checked () {
            return !!this.modelValue;
        }
    },
    methods: {
        onInput (event) {
            this.$emit('update:modelValue', event.target.checked);
        }
    }
};
</script>

<style>
.VvCheckboxBinary--Default {
    @apply h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded;
}
</style>
