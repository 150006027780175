<template>
    <div
        class="py-12 space-y-12"
    >
        <slot></slot>
    </div>
</template>

<script>
/**
 * Common wrapper.
 *
 * It must be used to wrap views components and layout components.
 *
 * @author Dimitris Gkoulis
 * @createdAt 27 February 2021
 */
export default {
    name: 'CommonWrapper'
};
</script>
