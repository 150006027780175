/****************************************************************************************************
 * MS Websites "PageDefinition" resource API Client.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 31 January 2021
 * @lastModifiedAt Saturday 11 December 2021
 ****************************************************************************************************/

import ApiUrlProvider from '../api-url-provider';
import BaseApiClient from '../base-api-client';

const WebsitesPageDefinitionBasicApiClient = {
    createPageDefinition (data) {
        const url = ApiUrlProvider.kernelVersionedApi('page-definitions');
        return BaseApiClient.post(url, data);
    },
    updatePageDefinition (data) {
        const url = ApiUrlProvider.kernelVersionedApi('page-definitions');
        return BaseApiClient.put(url, data);
    },
    getPageDefinition (id) {
        const url = ApiUrlProvider.kernelVersionedApi('page-definitions/' + id);
        return BaseApiClient.get(url);
    },
    deletePageDefinition (id) {
        const url = ApiUrlProvider.kernelVersionedApi('page-definitions/' + id);
        return BaseApiClient.delete(url);
    },
    getAllPageDefinitions (websiteId, page = 0, size = 10, sortField = 'id', sortDirection = 'desc') {
        const url = ApiUrlProvider.kernelVersionedApi('page-definitions');
        return BaseApiClient.get(url, {
            params: {
                websiteId: websiteId,
                page: page,
                size: size,
                sort: sortField + ',' + sortDirection
            }
        });
    },

    savePropertyGroup (id, data) {
        const url = ApiUrlProvider.kernelVersionedApi('page-definitions/' + id + '/property-groups');
        return BaseApiClient.post(url, data);
    },
    deletePropertyGroup (id, data) {
        const url = ApiUrlProvider.kernelVersionedApi('page-definitions/' + id + '/property-groups');
        return BaseApiClient.delete(url, {
            data: data
        });
    },

    createPropertyDefinition (id, data) {
        const url = ApiUrlProvider.kernelVersionedApi('page-definitions/' + id + '/property-definitions');
        return BaseApiClient.post(url, data);
    },
    updatePropertyDefinition (id, data) {
        const url = ApiUrlProvider.kernelVersionedApi('page-definitions/' + id + '/property-definitions');
        return BaseApiClient.put(url, data);
    },
    deletePropertyDefinition (id, data) {
        const url = ApiUrlProvider.kernelVersionedApi('page-definitions/' + id + '/property-definitions');
        return BaseApiClient.delete(url, {
            data: data
        });
    },

    /* Combines: PropertyDefinition + RelationDefinition = Relation */

    createRelation (id, data) {
        const url = ApiUrlProvider.kernelVersionedApi('page-definitions/' + id + '/relations');
        return BaseApiClient.post(url, data);
    },
    updateRelation (id, data) {
        const url = ApiUrlProvider.kernelVersionedApi('page-definitions/' + id + '/relations');
        return BaseApiClient.put(url, data);
    },
    deleteRelation (id, data) {
        const url = ApiUrlProvider.kernelVersionedApi('page-definitions/' + id + '/relations');
        return BaseApiClient.delete(url, {
            data: data
        });
    }
};

export default WebsitesPageDefinitionBasicApiClient;
