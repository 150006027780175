/**
 * Head Meta options.
 */
const options = {
    titleOd: null,
    ods: null // On Destroy Strategy

    // we definitely need more fields in here.
};

/**
 * Head Meta options API.
 */
const HeadMetaOptions = {
    getOption (key) {
        return options[key];
    },
    hasValidOption (key) {
        if (typeof key !== 'string') {
            return false;
        }
        return options[key] !== null && options[key] !== undefined;
    },
    setOption (key, value) {
        if ({}.hasOwnProperty.call(options, key)) {
            options[key] = value;
        }
    }
};

export default HeadMetaOptions;
