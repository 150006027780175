/****************************************************************************************************
 * Date Utilities.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 21 February 2021
 ****************************************************************************************************/

const DateUtils = {};

// https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
DateUtils.isValidDate = function (d) {
    if (d === null || d === undefined) {
        return false;
    }
    if (Object.prototype.toString.call(d) === "[object Date]") {
        // noinspection RedundantIfStatementJS
        if (isNaN(d.getTime())) {  // d.valueOf() could also work
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
};

DateUtils.getDaysInMonth = function (year, month) {
    // IMPORTANT: parameter month is 0-indexed.
    // However, month in 'new Date()' is 1-indexed (January is 1, February is 2, etc).
    // This is because we're using 0 as the day so that it returns the last day
    // of the last month, so you have to add 1 to the month number
    // so it returns the correct amount of days.
    return new Date(year, (month + 1), 0).getDate();
};

export default DateUtils;
