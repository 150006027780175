/****************************************************************************************************
 * MS Websites "EdgeNode" resource API Client.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 January 2021
 * @lastModifiedAt Saturday 11 December 2021
 ****************************************************************************************************/

import ApiUrlProvider from '../api-url-provider';
import BaseApiClient from '../base-api-client';

const WebsitesEdgeNodeBasicApiClient = {
    getEdgeNodeDomainSetup () {
        const url = ApiUrlProvider.kernelVersionedApi('edge-nodes/domain-setups/');
        return BaseApiClient.get(url);
    }
};

export default WebsitesEdgeNodeBasicApiClient;
