<template>
    <CommonErrorState
        class="mb-4"
        v-if="uploadError"
        :error="uploadError"
    />
    <form enctype="multipart/form-data" novalidate>
        <div class="EtbModuleCustomizerControlFileImage__Dropbox">
            <input
                type="file"
               :name="uploadFieldName"
               :disabled="uploadLoading"
               @change="onFilesChange($event.target.name, $event.target.files)"
               class="EtbModuleCustomizerControlFileImage__Dropbox__InputFile"
            />
            <div class="EtbModuleCustomizerControlFileImage__Dropbox__Cosmetics">
                <CommonLoading
                    v-if="uploadLoading"
                    class="py-10 sm:py-15 md:py-20"
                />
                <p
                    v-else
                    class="text-gray-700 text-sm font-medium"
                >
                    {{ $t('Choose a file or drag it here') }}
                </p>
            </div>
        </div>
    </form>
</template>
<script>
import { ref } from 'vue';
import { StorageStoredObjectBasicApiClient } from '@/core/api';

/**
 * A component for exploring and managing StoredObject entities.
 *
 * @author Dimitris Gkoulis
 * @createdAt 22 February 2021
 */
export default {
    name: 'StoredObjectExplorerUpload',
    emits: ['upload'],
    setup (props, { emit }) {
        const uploadFieldName = 'file'; // Constant.

        const uploadLoading = ref(false);
        const uploadError = ref(null);
        const uploadedStoredObject = ref(null);

        const onFilesChange = async (fieldName, fileList) => {
            if (uploadLoading.value === true) return;

            if (!fileList.length) return;
            if (fileList.length > 1) return; // One file per upload. Multiple files upload is not supported!

            const file = fileList[0];

            uploadLoading.value = true;
            uploadError.value = null;
            uploadedStoredObject.value = null;

            await StorageStoredObjectBasicApiClient.uploadContentStoredObject(file)
                .then(({ data }) => {
                    uploadedStoredObject.value = data;
                    emit('upload', data);
                })
                .catch((reason) => {
                    uploadError.value = reason;
                })
                .finally(() => {
                    uploadLoading.value = false;
                });
        };

        return {
            uploadFieldName,
            uploadLoading,
            uploadError,
            onFilesChange
        };
    }
};
</script>

<style lang="scss">
$checkerboard-pattern-color: #dcdcdc; // #808080.

.EtbModuleCustomizerControlFileImage__Dropbox {
    position: relative;
    background-color: transparent;
    outline: 2px dashed #dcdcdc;
    outline-offset: -3px;
    min-height: 120px;
    cursor: pointer;
    transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    p {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}
.EtbModuleCustomizerControlFileImage__Dropbox:hover {
    background-color: #f4f4f4;
}
.EtbModuleCustomizerControlFileImage__Dropbox__InputFile {
    opacity: 0; /* invisible but it's there! */
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    cursor: pointer;
}
.EtbModuleCustomizerControlFileImage__Dropbox__Cosmetics {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    text-align: center;

    p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}
</style>
