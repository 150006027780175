<template>
    <div
        v-if="displayWrapper"
        class="fixed z-10 inset-0 overflow-y-auto"
    >
        <transition
            enter-active-class="ease-out duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-if="displayOverlay"
                class="fixed inset-0 transition-opacity"
                aria-hidden="true"
            >
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
        </transition>
        <transition
            enter-active-class="ease-out duration-300"
            enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <div
                v-if="displayContent"
                class="absolute bg-white rounded shadow-md z-40 top-20 right-4 bottom-4 left-4 md:top-28 md:right-10 md:bottom-10 md:left-10 lg:top-10"
            >
                <slot></slot>
            </div>
        </transition>
    </div>
</template>
<script>
import { ref, toRefs, watch } from 'vue';

/**
 * A modal component designed for the StoredObjectExplorer.
 *
 * @author Dimitris Gkoulis
 * @createdAt 22 February 2021
 * @lastModifiedAt 23 February 2021
 */
export default {
    name: 'StoredObjectExplorerModal',
    props: {
        open: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    setup (props) {
        const { open } = toRefs(props);

        const displayWrapper = ref(false);
        const displayOverlay = ref(false);
        const displayContent = ref(false);

        watch(open, (newValue) => {
            if (newValue === true) {
                displayWrapper.value = true;
                setTimeout(function () {
                    displayOverlay.value = true;
                    displayContent.value = true;
                }, 60);
            } else {
                displayContent.value = false;
                displayOverlay.value = false;
                setTimeout(function () {
                    displayWrapper.value = false;
                }, 340);
            }
        });

        return {
            displayWrapper,
            displayOverlay,
            displayContent
        };
    }
};
</script>
