<template>
    <div class="absolute top-0 right-0 bottom-0 left-0 bg-white" v-if="active">
        <Spinner/>
    </div>
</template>

<script>
import Spinner from '../Spinner/Spinner';

/**
 * Common blocking loading.
 *
 * @future Implementations:
 * (1) Lock Scroll in parent component.
 *     Watch "active" : lock / unlock.
 *     on mount : lock (if "active" is true)
 *     on unmount : unlock regardless the value of "active"
 *
 * @author Dimitris Gkoulis
 * @createdAt 13 January 2021
 */
export default {
    name: 'CommonLoadingBlocking',
    components: {
        Spinner
    },
    props: {
        active: Boolean
    }
};
</script>
