<!--suppress HtmlFormInputWithoutLabel -->
<template>
    <label
        v-if="!!label"
        :for="name"
        class="VvLabel"
    >
        {{ label }} <span class="text-xs font-normal text-gray-400" v-if="optional">{{ optionalText }}</span>
    </label>
    <div :class="{ 'mt-1': !!label }">
        <div class="relative w-1/2" style="min-height: 40px;">
            <CommonLoading
                v-if="storedObjectLoading"
            />
            <StoredObjectItem
                v-if="storedObject"
                :tabindex="tabIndex"
                :stored-object="storedObject"
                :display-recent="true"
                :display-remove="true"
                :allow-click="false"
                v-on:remove-clicked="removeSelectedStoredObject"
            />
            <div
                v-else
                :tabindex="tabIndex"
                @click="openStoredObjectExplorerModal"
                class="relative flex flex-col border border-2 border-dashed rounded shadow-sm cursor-pointer"
            >
                <div class="flex-grow p-2 inline-flex flex-col justify-center h-40 text-center">
                    <p class="text-blue-700 text-sm font-semibold">
                        {{ $t('common_pick_single_stored_object_explorer') }} <span>→</span>
                    </p>
                </div>
            </div>
        </div>
        <div
            v-if="storedObject"
            class="mt-2"
        >
            <Button
                @click="openStoredObjectExplorerModal"
                variation="white"
            >
                {{ $t('common_add_replace_single_stored_object') }}
            </Button>
        </div>
        <StoredObjectExplorerModal
            :open="storedObjectExplorerModalOpen"
        >
            <StoredObjectExplorer
                :select-type="'single'"
                :initial-value-for-single="storedObject"
                :initial-values-for-multiple="[]"
                :display-close-button="true"
                v-on:select-single="handleSelectSingle"
                v-on:close="closeStoredObjectExplorerModal"
            />
        </StoredObjectExplorerModal>
    </div>
    <div class="mt-2">
        <ErrorMessage
            :name="name"
            v-slot="{ message }"
        >
            <p class="VvErrorMessage__Text">{{ message }}</p>
        </ErrorMessage>
    </div>
    <div class="mt-2">
        <p v-if="!!helpText" class="VvHelpText--Default">{{ helpText }}</p>
    </div>
</template>
<script>
import { ref, toRefs, onBeforeMount } from 'vue';
import { useField, ErrorMessage } from 'vee-validate';
import { StorageStoredObjectBasicApiClient } from '@/core/api';
import { I18nPlugin } from '@/core/plugins';
import {
    StoredObjectExplorer,
    StoredObjectExplorerModal,
    StoredObjectItem
} from '@/core/modules/StoredObjectExplorer';
import veeValidateProps from './veeValidateProps';

const i18n = I18nPlugin.getDefaultI18nInstance();

/**
 * StoredObject input/picker based on VeeValidate.
 *
 * @future implementations:
 * - we need somehow to notify user if the initialValue does not actually point to an existing StoredObject entity.
 * - handle errors (in initialValue fetch) (not the missing value (404), the ERRORS)
 * - file format / mime type / etc limitations (and filter the results in StoredObjectExplorer)
 *   Check comments in StoredObjectExplorer too.
 *
 * @author Dimitris Gkoulis
 * @createdAt 21 February 2021
 * @lastModifiedAt 24 February 2021
 */
export default {
    name: 'VvStoredObjectInput',
    inheritAttrs: false,
    components: {
        ErrorMessage,
        StoredObjectExplorer,
        StoredObjectExplorerModal,
        StoredObjectItem
    },
    emits: [
        'update:modelValue',
        'update-batch'
    ],
    props: {
        name: {
            type: String,
            required: true
        },
        label: String,
        optional: Boolean,
        optionalText: {
            type: String,
            default: function () {
                return i18n.global.t('Optional');
            }
        },
        helpText: String,
        modelValue: null, // Expected to be String or null.
        tabIndex: Number,
        // FormVueLate + VeeValidate specifics.
        ...veeValidateProps()
    },
    setup (props, { emit }) {
        const { name, label, modelValue, validations } = toRefs(props);
        let initialValue = modelValue ? modelValue.value : null;

        const {
            handleChange
        } = useField(name, validations, {
            initialValue: initialValue,
            label: label,
            type: Date
        });

        const storedObjectLoading = ref(false);
        const storedObject = ref(null);
        // If initialValue is not null, get StoredObject.
        // If StoredObject entity does not exist, use handleChange to change value and trigger validation.
        onBeforeMount(() => {
            if (typeof initialValue === 'string') {
                storedObjectLoading.value = true;
                StorageStoredObjectBasicApiClient.getStoredObjectById(initialValue)
                    .then(({ data }) => {
                        storedObject.value = data;
                    })
                    .catch(() => {
                        storedObject.value = null;
                        handleChange(null);
                    })
                    .finally(() => {
                        storedObjectLoading.value = false;
                    });
            }
        });

        const storedObjectExplorerModalOpen = ref(false);
        const openStoredObjectExplorerModal = () => {
            storedObjectExplorerModalOpen.value = true;
        };
        const closeStoredObjectExplorerModal = () => {
            storedObjectExplorerModalOpen.value = false;
        };
        const switchStoredObjectExplorerModalOpen = () => {
            storedObjectExplorerModalOpen.value = !storedObjectExplorerModalOpen.value;
        };

        const handleSelectSingle = (newStoredObject) => {
            storedObject.value = newStoredObject;
            storedObjectExplorerModalOpen.value = false;
            handleChange(storedObject.value.id);
            emit('update:modelValue', storedObject.value.id);
        };
        const removeSelectedStoredObject = () => {
            storedObject.value = null;
            handleChange(null);
            emit('update:modelValue', null);
        };

        return {
            storedObjectLoading,
            storedObject,
            storedObjectExplorerModalOpen,
            openStoredObjectExplorerModal,
            closeStoredObjectExplorerModal,
            switchStoredObjectExplorerModalOpen,
            handleSelectSingle,
            removeSelectedStoredObject
        };
    }
};
</script>
