<!--suppress HtmlFormInputWithoutLabel -->
<template>
    <label
        v-if="!!label"
        :for="name"
        class="VvLabel"
    >
        {{ label }} <span class="text-xs font-normal text-gray-400" v-if="optional">{{ optionalText }}</span>
    </label>
    <div :class="{ 'mt-1': !!label }">
        <div class="relative w-full" style="min-height: 40px;">
            <CommonLoading
                v-if="storedObjectListLoading"
            />
            <template
                v-if="storedObjectList.length > 0"
            >
                <!-- GRID -->
                <!-- Another idea: 2 - 3 - 5 - 8 - 11 -->
                <draggable
                    class="mb-4 grid grid-flow-col grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 grid-flow-row-dense gap-4"
                    ghost-class="StoredObjectItem--DnD--Ghost"
                    drag-class="StoredObjectItem--DnD--Drag"
                    :list="storedObjectList"
                    @change="handleOrderChange"
                >
                    <StoredObjectItem
                        v-for="storedObject in storedObjectList"
                        :key="storedObject.id"
                        :tabindex="tabIndex"
                        :stored-object="storedObject"
                        :display-recent="true"
                        :display-remove="true"
                        :allow-click="false"
                        :allow-drag="true"
                        v-on:remove-clicked="removeSelectedStoredObject"
                    />
                </draggable>
            </template>
            <div
                v-else
                :tabindex="tabIndex"
                @click="openStoredObjectExplorerModal"
                class="relative flex flex-col border border-2 border-dashed rounded shadow-sm cursor-pointer"
            >
                <div class="flex-grow p-2 inline-flex flex-col justify-center h-40 text-center">
                    <p class="text-blue-700 text-sm font-semibold">
                        {{ $t('common_pick_multiple_stored_object_explorer') }} <span>→</span>
                    </p>
                </div>
            </div>
        </div>
        <div
            v-if="storedObjectList.length > 0"
            class="mt-2"
        >
            <Button
                @click="openStoredObjectExplorerModal"
                variation="white"
            >
                {{ $t('common_add_replace_multiple_stored_object') }}
            </Button>
        </div>
        <StoredObjectExplorerModal
            :open="storedObjectExplorerModalOpen"
        >
            <StoredObjectExplorer
                :select-type="'multiple'"
                :initial-value-for-single="null"
                :initial-values-for-multiple="storedObjectList"
                :display-close-button="true"
                v-on:select-multiple="handleSelectMultiple"
                v-on:close="closeStoredObjectExplorerModal"
            />
        </StoredObjectExplorerModal>
    </div>
    <div class="mt-2">
        <ErrorMessage
            :name="name"
            v-slot="{ message }"
        >
            <p class="VvErrorMessage__Text">{{ message }}</p>
        </ErrorMessage>
    </div>
    <div class="mt-2">
        <p v-if="!!helpText" class="VvHelpText--Default">{{ helpText }}</p>
    </div>
</template>
<script>
import { ref, toRefs, onBeforeMount } from 'vue';
import { useField, ErrorMessage } from 'vee-validate';
import { VueDraggableNext } from 'vue-draggable-next';
import { StorageStoredObjectBasicApiClient } from '@/core/api';
import { I18nPlugin } from '@/core/plugins';
import {
    StoredObjectExplorer,
    StoredObjectExplorerModal,
    StoredObjectItem
} from '@/core/modules/StoredObjectExplorer';
import veeValidateProps from './veeValidateProps';

const i18n = I18nPlugin.getDefaultI18nInstance();

/**
 * Multiple StoredObject input/picker based on VeeValidate.
 *
 * @future implementations:
 * - THE SAME AS VvStoredObjectInput.vue PLUS:
 * - Remove all (with confirmation)
 * - View more
 *   (if there are too many files,
 *   add three dots button and on click show all)
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 February 2021
 */
export default {
    name: 'VvListStoredObjectInput',
    inheritAttrs: false,
    components: {
        ErrorMessage,
        draggable: VueDraggableNext,
        StoredObjectExplorer,
        StoredObjectExplorerModal,
        StoredObjectItem
    },
    emits: [
        'update:modelValue',
        'update-batch'
    ],
    props: {
        name: {
            type: String,
            required: true
        },
        label: String,
        optional: Boolean,
        optionalText: {
            type: String,
            default: function () {
                return i18n.global.t('Optional');
            }
        },
        helpText: String,
        modelValue: Array, // Expected to be Array
        tabIndex: Number,
        // FormVueLate + VeeValidate specifics.
        ...veeValidateProps()
    },
    setup (props, { emit }) {
        const { name, label, modelValue, validations } = toRefs(props);
        let initialValue = modelValue ? modelValue.value : [];
        if (!Array.isArray(initialValue)) initialValue = [];
        initialValue = initialValue.filter((i) => i !== null);

        const {
            handleChange
        } = useField(name, validations, {
            initialValue: initialValue,
            label: label,
            type: Date
        });

        const storedObjectListLoading = ref(false);
        const storedObjectList = ref([]);
        // If initialValue is not null, get StoredObject.
        // If StoredObject entity does not exist, use handleChange to change value and trigger validation.
        onBeforeMount(() => {
            if (initialValue.length > 0) {
                storedObjectListLoading.value = true;
                StorageStoredObjectBasicApiClient.getStoredObjectsByIds(initialValue)
                    .then(({ data }) => {
                        storedObjectList.value = data
                            .map(function (storedObject) {
                                storedObject['_selectedAt'] = initialValue.indexOf(storedObject.id);
                                return storedObject;
                            })
                            .sort((a, b) => (a._selectedAt - b._selectedAt));

                        // Not all entities found!
                        if (storedObjectList.value.length !== initialValue.length) {
                            const idList = storedObjectList.value.map((i) => i.id);
                            handleChange(idList);
                        }
                    })
                    .catch(() => {
                        storedObjectList.value = [];
                        handleChange([]);
                    })
                    .finally(() => {
                        storedObjectListLoading.value = false;
                    });
            }
        });

        const storedObjectExplorerModalOpen = ref(false);
        const openStoredObjectExplorerModal = () => {
            storedObjectExplorerModalOpen.value = true;
        };
        const closeStoredObjectExplorerModal = () => {
            storedObjectExplorerModalOpen.value = false;
        };
        const switchStoredObjectExplorerModalOpen = () => {
            storedObjectExplorerModalOpen.value = !storedObjectExplorerModalOpen.value;
        };

        const handleSelectMultiple = (newStoredObjectList) => {
            if (!Array.isArray(newStoredObjectList)) return;
            storedObjectList.value = newStoredObjectList;
            storedObjectExplorerModalOpen.value = false;
            const idList = storedObjectList.value.map((i) => i.id);
            handleChange(idList);
            emit('update:modelValue', idList);
        };
        const removeSelectedStoredObject = (storedObject) => {
            const index = storedObjectList.value.findIndex((i) => i.id === storedObject.id);
            if (index === -1) return;
            storedObjectList.value.splice(index, 1);
            const idList = storedObjectList.value.map((i) => i.id);
            handleChange(idList);
            emit('update:modelValue', idList);
        };

        const handleOrderChange = () => {
            const idList = storedObjectList.value.map((i) => i.id);
            handleChange(idList);
            emit('update:modelValue', idList);
        };

        return {
            storedObjectListLoading,
            storedObjectList,
            storedObjectExplorerModalOpen,
            openStoredObjectExplorerModal,
            closeStoredObjectExplorerModal,
            switchStoredObjectExplorerModalOpen,
            handleSelectMultiple,
            removeSelectedStoredObject,
            handleOrderChange
        };
    }
};
</script>
