import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import throttle from 'lodash/throttle';
import { UserLogic } from '@/core/logic';
import { gtagConfig } from '@/core/modules/MarketingAnalytics';
import {
    CLOUTLAYER_WEBAPP_CORE_LOGIN_URL
} from '@/core/environment';
import {
    ApiErrorTransformer,
    AccountsTenantAccountBasicApiClient,
    AuthBasicApiClient,
    UserAccountBasicApiClient
} from '@/core/api';

const LAZY_AUTH_WAIT = 2500; // 2,5 sec.

/**
 * Application composable.
 *
 * @author Dimitris Gkoulis
 * @createdAt 30 January 2021
 * @lastModifiedAt Saturday 11 December 2021
 */
export default function useApplication () {
    const store = useStore();
    const { locale, setLocaleMessage } = useI18n({ useScope: 'global' });

    const setUpI18n = async function (candidateLanguageKey, callable = null) {
        const candidateLanguageKeyCleaned = typeof candidateLanguageKey === 'string' ? candidateLanguageKey.trim().toLowerCase() : 'en';
        const languageKey = ['en', 'el'].some(i => i === candidateLanguageKeyCleaned) ? candidateLanguageKeyCleaned : 'en';

        locale.value = languageKey;

        if (typeof callable === 'function') {
            const { messages } = await callable(languageKey)
                .then((data) => data)
                .catch(() => {
                    return {
                        messages: {}
                    };
                });
            setLocaleMessage(languageKey, messages);
        }

        document.querySelector('html').setAttribute('lang', languageKey);

        return Promise.resolve(languageKey);
    };

    const initializeNonMultiTenantApp = async function (setUpI18nCallable = null) {
        // Check if application is already initialized (regardless if it's not ready!).
        if (store.getters['application/initialized']) {
            return Promise.resolve({
                success: true,
                state: 'INITIALIZED'
            });
        }

        await store.dispatch('application/resetModule').then(() => void 0).catch(() => void 0);

        // This is also authentication
        // (if user is not authenticated, operation will fail).
        const user = await UserAccountBasicApiClient.getUser()
            .then(({ data }) => {
                return data;
            })
            .catch(() => null);

        if (user === null) {
            store.commit('application/setStateProp', { key: 'initialized', value: true });
            store.commit('application/setStateProp', { key: 'initializedAndReady', value: false });

            return Promise.resolve({
                success: false,
                state: 'USER_NULL'
            });
        }

        gtagConfig({
            user_id: user.id
        });

        store.commit('application/setStateProp', { key: 'user', value: user });

        await setUpI18n(user['langKey'], setUpI18nCallable).then(() => void 0).catch(() => void 0);

        // Get all TenantAccount entities in which User has access to.
        const tenantAccountList = await AccountsTenantAccountBasicApiClient.getAllTenantAccounts()
            .then(({ data }) => {
                return data;
            })
            .catch(() => []);

        store.commit('application/setStateProp', { key: 'tenantAccountList', value: tenantAccountList });

        store.commit('application/setStateProp', { key: 'initialized', value: true });
        store.commit('application/setStateProp', { key: 'initializedAndReady', value: true });

        return Promise.resolve({
            success: true,
            state: 'READY'
        });
    };

    const initializeMultiTenantApp = async function (tenantId, setUpI18nCallable = null) {
        const currentTenantId = tenantId;

        // Check if application is already initialized (regardless if it's not ready!).
        if (store.getters['application/initialized']) {
            return Promise.resolve({
                success: true,
                state: 'INITIALIZED'
            });
        }

        await store.dispatch('application/resetModule').then(() => void 0).catch(() => void 0);

        if (typeof currentTenantId !== 'string') {
            store.commit('application/setStateProp', { key: 'initialized', value: true });
            store.commit('application/setStateProp', { key: 'initializedAndReady', value: false });

            return Promise.resolve({
                success: false,
                state: 'INVALID_TENANT_ID'
            });
        }

        store.commit('application/setStateProp', { key: 'currentTenantId', value: currentTenantId });

        // This is also authentication
        // (if user is not authenticated, operation will fail).
        const user = await UserAccountBasicApiClient.getUser()
            .then(({ data }) => {
                return data;
            })
            .catch(() => null);

        if (user === null) {
            store.commit('application/setStateProp', { key: 'initialized', value: true });
            store.commit('application/setStateProp', { key: 'initializedAndReady', value: false });

            return Promise.resolve({
                success: false,
                state: 'USER_NULL'
            });
        }

        gtagConfig({
            user_id: user.id
        });

        store.commit('application/setStateProp', { key: 'user', value: user });

        await setUpI18n(user['langKey'], setUpI18nCallable).then(() => void 0).catch(() => void 0);

        const accessLevels = UserLogic.getAccessLevels(user, currentTenantId);
        store.commit('application/setStateProp', { key: 'userHasTenantUserAccessLevel', value: accessLevels.hasTenantUserAccessLevel });
        store.commit('application/setStateProp', { key: 'userHasTenantAdminAccessLevel', value: accessLevels.hasTenantAdminAccessLevel });

        // userHasTenantAdminAccessLevel > userHasTenantUserAccessLevel
        // userHasTenantAdminAccessLevel implies also userHasTenantUserAccessLevel
        // NOTE that this check is based on roles that the user object has.
        if (store.getters['application/userHasTenantUserAccessLevel'] !== true) {
            store.commit('application/setStateProp', { key: 'initialized', value: true });
            store.commit('application/setStateProp', { key: 'initializedAndReady', value: false });

            return Promise.resolve({
                success: false,
                state: 'ACCESS_DENIED_1'
            });
        }

        // NOTE that this operation will fail if user has not actually access to tenant.
        const tenantAccount = await AccountsTenantAccountBasicApiClient.getTenantAccountById(currentTenantId)
            .then(({ data }) => {
                return data;
            })
            .catch(() => null);

        if (tenantAccount === null) {
            store.commit('application/setStateProp', { key: 'initialized', value: true });
            store.commit('application/setStateProp', { key: 'initializedAndReady', value: false });

            return Promise.resolve({
                success: false,
                state: 'TENANT_ACCOUNT_NULL'
            });
        }

        store.commit('application/setStateProp', { key: 'tenantAccount', value: tenantAccount });

        // Get all TenantAccount entities in which User has access to.
        const tenantAccountList = await AccountsTenantAccountBasicApiClient.getAllTenantAccounts()
            .then(({ data }) => {
                return data;
            })
            .catch(() => []);

        store.commit('application/setStateProp', { key: 'tenantAccountList', value: tenantAccountList });

        store.commit('application/setStateProp', { key: 'initialized', value: true });
        store.commit('application/setStateProp', { key: 'initializedAndReady', value: true });

        return Promise.resolve({
            success: true,
            state: 'READY'
        });
    };

    const runMultiTenantAppRouteGuard = async (toRoute) => {
        if (toRoute === null || toRoute === undefined) {
            return Promise.resolve({
                success: false,
                state: 'ROUTE_NULL'
            });
        }

        const tenantId = toRoute.params['tenantId'];

        if (typeof tenantId !== 'string') {
            return Promise.resolve({
                success: false,
                state: 'INVALID_TENANT_ID'
            });
        }

        const currentTenantId = store.getters['application/currentTenantId'];

        if (tenantId !== currentTenantId) {
            return Promise.resolve({
                valid: false,
                state: 'TENANT_ID_MISMATCH'
            });
        }

        // Normally we would authenticate user here.
        // But some views -usually those which have many levels of children view- change multiple times the route.

        return Promise.resolve({
            success: true,
            state: 'OKAY'
        });
    };

    // @future IMPORTANT (Thursday 30 December 2021):
    // Reduce the number of times it called...
    // I think that it does not work as expected!
    const authenticateOrRedirectLazy = throttle(async function (toRoute) {
        const userLogin = await AuthBasicApiClient.whoAmI()
            .then(({ data }) => {
                if (typeof data !== 'string') return null;
                if (data.trim() === '') return null;
                if (data.trim() === 'anonymous') return null;
                return data.trim();
            })
            .catch(() => null);
        if (typeof userLogin !== 'string') {
            redirectToUrl(getRedirectUrlBasedOnRoute(toRoute));
        }
    }, LAZY_AUTH_WAIT, { leading: true, trailing: false });

    const runLazyAuthRouteGuard = async (toRoute) => {
        authenticateOrRedirectLazy(toRoute);
        return Promise.resolve(true);
    };

    // Intercept all requests and add current tenantId in HTTP Headers.
    const initializeAxiosTenantIdInterceptor = function (axiosInstance) {
        axiosInstance.interceptors.request.use(function (config) {
            const tenantAccountId = store.getters['application/currentTenantId'];
            if (tenantAccountId != null) {
                config.headers['X-Tenant-ID'] = tenantAccountId;
            }
            return config;
        }, null);
    };

    // Intercept failed responses and transform error response.
    const initializeAxiosErrorTransformingInterceptor = function (axiosInstance) {
        axiosInstance.interceptors.response.use(null, (error) => {
            error = ApiErrorTransformer.flattenError(error);
            return Promise.reject(error);
        });
    };

    const setApplicationStateNotReady = function () {
        store.commit('application/setStateProp', { key: 'initializedAndReady', value: false });
    };

    const getRedirectUrlBasedOnRoute = function (toRoute) {
        let redirect = CLOUTLAYER_WEBAPP_CORE_LOGIN_URL;
        if (toRoute.matched.length > 0) {
            // redirect = redirect + '?redirect=' + CLOUTLAYER_WEBAPP_URL + toRoute.fullPath;
            redirect = redirect + '?redirect=' + window.location.href;
        }
        return redirect;
    };

    const redirectToUrl = function (url) {
        window.location.replace(url);
    };

    return {
        initializeNonMultiTenantApp,
        initializeMultiTenantApp,
        runMultiTenantAppRouteGuard,
        runLazyAuthRouteGuard,
        initializeAxiosTenantIdInterceptor,
        initializeAxiosErrorTransformingInterceptor,
        setApplicationStateNotReady,
        getRedirectUrlBasedOnRoute,
        redirectToUrl
    };
}
