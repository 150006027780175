import { ref } from 'vue';
import { PolyglotPersistenceBasicApiClient } from '@/core/api';

export default function useEntitySpace (identifierRef) {
    const loadingRef = ref(false);
    const entityRef = ref(null);
    const getEntity = async () => {
        loadingRef.value = true;
        if (typeof identifierRef.value === 'string') {
            // noinspection JSValidateTypes
            entityRef.value = await PolyglotPersistenceBasicApiClient.getEntitySpace(identifierRef.value)
                .then(({ data }) => {
                    return Promise.resolve(data);
                })
                .catch(() => {
                    return Promise.resolve(null);
                });
        } else {
            entityRef.value = null;
        }
        loadingRef.value = false;
    };

    return {
        entitySpaceLoading: loadingRef,
        entitySpace: entityRef,
        getEntitySpace: getEntity
    };
}
