import get from 'lodash/get';

const ApiErrorTransformer = {
    flattenError (error) {
        if (!(error instanceof Error)) {
            return error;
        }

        error.httpStatus = get(error, 'response.status', 0);
        error.httpStatusText = get(error, 'response.statusText', null);

        error.dataTitle = get(error, 'response.data.title', null);
        // Usually information on how to solve the problem.
        error.dataDetail = get(error, 'response.data.detail', null);
        // Usually represents the error and it is more likely
        // to be a machine-readable message rather a human-readable one.
        error.dataMessage = get(error, 'response.data.message', null);
        error.dataStatus = get(error, 'response.data.status', null);
        error.dataPath = get(error, 'response.data.path', null);
        error.dataType = get(error, 'response.data.type', null);

        error.dataEntity = get(error, 'response.data.entity', null);
        error.dataService = get(error, 'response.data.service', null);

        error.dataErrors = get(error, 'response.data.errors', []);
        error.dataParams = get(error, 'response.data.params', {});
        error.dataTimestamp = get(error, 'response.data.timestamp', null);

        error.dataError = get(error, 'response.data.error', null);

        return error;
    }
};

/**
 * Provides common transformations over errors thrown by axios.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 30 March 2020
 * @lastModifiedAt 4 March 2021
 */
export default ApiErrorTransformer;
