import { computed, watch, ref, readonly } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import isEqual from 'lodash/isEqual';
import { PaginationUtils } from '@/core/utils';

/**
 * [BETA]: A composable that provides route based pagination.
 *
 * @author Dimitris Gkoulis
 * @createdAt 14 April 2021
 */
export default function useCommonReactiveRouteBasedPaginatorBeta (
    totalElementsCountRef, // Total elements in query.
    elementsCountRef, // Elements in (local) list (ie elements in current page).
    initialPage = 0,
    initialSize = 10,
    initialSortField = 'id',
    initialSortType = 'desc'
) {
    const route = useRoute();
    const router = useRouter();

    const paginationOptions = ref({
        page: initialPage,
        size: initialSize,
        sortField: initialSortField,
        sortType: initialSortType
    });
    const refreshPaginationOptionsFromRouteQuery = (query) => {
        let newPage = query['page'];
        if (typeof newPage === 'string') {
            newPage = parseInt(newPage, 10);
        } else {
            newPage = initialPage;
        }

        let newSize = query['size'];
        if (typeof newSize === 'string') {
            newSize = parseInt(newSize, 10);
        } else {
            newSize = initialSize;
        }

        let newSortField = query['sortField'];
        if (typeof newSortField !== 'string') {
            newSortField = initialSortField;
        }

        let newSortType = query['sortType'];
        if (typeof newSortType !== 'string') {
            newSortType = initialSortType;
        }

        paginationOptions.value.page = newPage;
        paginationOptions.value.size = newSize;
        paginationOptions.value.sortField = newSortField;
        paginationOptions.value.sortType = newSortType;
    };
    refreshPaginationOptionsFromRouteQuery(route.query); // Not guaranteed but necessary.

    const paginationDetails = ref(null);
    const updatePaginationDetails = () => {
        paginationDetails.value = PaginationUtils.getPaginationDetails({
            pageSize: paginationOptions.value.size,
            count: elementsCountRef.value,
            totalCount: totalElementsCountRef.value,
            currentPage: (paginationOptions.value.page + 1)
        });
    };
    updatePaginationDetails(); // Not guaranteed but necessary.

    const goToNextPage = () => {
        // if (paginationDetails.value.hasNotNext) {
        //     return;
        // }
        router.push({
            query: {
                page: (paginationOptions.value.page + 1)
            }
        }).then(() => void 0).catch(() => void 0);
    };
    const goToLastPage = () => {
        // if (paginationDetails.value.hasNotNext) {
        //     return;
        // }
        router.push({
            query: {
                page: (paginationDetails.value.lastPage - 1)
            }
        }).then(() => void 0).catch(() => void 0);
    };
    const goToPreviousPage = () => {
        // if (paginationDetails.value.hasNotPrevious) {
        //     return;
        // }
        router.push({
            query: {
                page: (paginationOptions.value.page - 1)
            }
        }).then(() => void 0).catch(() => void 0);
    };
    const goToFirstPage = () => {
        // if (paginationDetails.value.hasNotPrevious) {
        //     return;
        // }
        router.push({
            query: {
                page: 0
            }
        }).then(() => void 0).catch(() => void 0);
    };

    // Watches route query and updates 'paginationOptions' local object.
    watch(
        () => route.query,
        (newQuery) => refreshPaginationOptionsFromRouteQuery(newQuery),
        { immediate: true, deep: true }
    );

    // Watches 'paginationOptions' local object and external references to page items count and total items count.
    watch(
        [
            totalElementsCountRef,
            elementsCountRef,
            paginationOptions
        ],
        () => updatePaginationDetails(),
        { immediate: true, deep: true }
    );

    // A hacky implementation that changes the paginationIntend
    // only if the properties that affect listing have been changed.
    const paginationIntend = ref(null);
    const updatePaginationIntend = () => {
        const newPaginationIntend = {
            page: (paginationDetails.value.currentPage - 1),
            size: paginationDetails.value.pageSize,
            sortField: paginationOptions.value.sortField,
            sortType: paginationOptions.value.sortType
        };
        if (!isEqual(paginationIntend.value, newPaginationIntend)) {
            paginationIntend.value = newPaginationIntend;
        }
    };

    // Watches 'paginationDetails' local object and updates the paginationIntend if it's necessary.
    watch(
        [
            paginationDetails
        ],
        () => updatePaginationIntend(),
        { immediate: true, deep: true }
    );

    const paginationDetailsCopy = computed(() => {
        return {
            ...paginationDetails.value,
            sortField: paginationOptions.value.sortField,
            sortType: paginationOptions.value.sortType
        };
    });

    const paginationIntendCopy = readonly(paginationIntend);

    return {
        paginationDetails: paginationDetailsCopy,
        paginationIntend: paginationIntendCopy,
        goToNextPage,
        goToLastPage,
        goToPreviousPage,
        goToFirstPage
    };
}
