/****************************************************************************************************
 * MS Accounts "TenantAccount" resource API Client.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 29 January 2021
 * @lastModifiedAt Saturday 11 December 2021
 ****************************************************************************************************/

import ApiUrlProvider from '../api-url-provider';
import BaseApiClient from '../base-api-client';

const AccountsTenantAccountBasicApiClient = {
    getAllTenantAccounts (page = 0, size = 100, sortField = 'id', sortDirection = 'desc') {
        const url = ApiUrlProvider.kernelVersionedApi('tenant-accounts', {
            params: {
                page: page,
                size: size,
                sort: sortField + ',' + sortDirection
            }
        });
        return BaseApiClient.get(url);
    },
    getTenantAccountById (id) {
        const url = ApiUrlProvider.kernelVersionedApi('tenant-accounts/' + id);
        return BaseApiClient.get(url);
    }
};

export default AccountsTenantAccountBasicApiClient;
