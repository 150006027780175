import cloneDeep from 'lodash/cloneDeep';
import VueGtag, { trackRouter, useGtag } from 'vue-gtag-next';

import {
    GTAG_ENABLED,
    GTAG_PROPERTY_ID,
    GTAG_DEBUG
} from '@/core/environment';

const DEFAULT_VUE_GTAG_CONFIG = {
    isEnabled: GTAG_ENABLED,
    useDebugger: GTAG_DEBUG,
    property: {
        id: GTAG_PROPERTY_ID,
        params: {} // user_id: "1234567890",
    }
};

export function enableGtag (vueApp, configObj = null) {
    if (configObj === null || configObj === undefined) {
        configObj = cloneDeep(DEFAULT_VUE_GTAG_CONFIG);
    }
    vueApp.use(VueGtag, configObj);
}

export function enableGtagTrackRouter (router) {
    trackRouter(router);
}

export function gtagConfig (configObj) {
    const { config } = useGtag();
    config({
        ...configObj
    });
}
