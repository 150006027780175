export const en = {
    'code': 'en',
    'messages': {
        // Built-in
        'alpha': 'The {field} field may only contain alphabetic characters',
        'alpha_num': 'The {field} field may only contain alpha-numeric characters',
        'alpha_dash': 'The {field} field may contain alpha-numeric characters as well as dashes and underscores',
        'alpha_spaces': 'The {field} field may only contain alphabetic characters as well as spaces',
        'between': 'The {field} field must be between 0:{min} and 1:{max}',
        'confirmed': 'The {field} field confirmation does not match',
        'digits': 'The {field} field must be numeric and exactly contain 0:{length} digits',
        'dimensions': 'The {field} field must be 0:{width} pixels by 1:{height} pixels',
        'email': 'The {field} field must be a valid email',
        'excluded': 'The {field} field is not a valid value',
        'ext': 'The {field} field is not a valid file',
        'image': 'The {field} field must be an image',
        'integer': 'The {field} field must be an integer',
        'length': 'The {field} field must be 0:{length} long',
        'max_value': 'The {field} field must be 0:{max} or less',
        'max': 'The {field} field may not be greater than 0:{length} characters',
        'mimes': 'The {field} field must have a valid file type',
        'min_value': 'The {field} field must be 0:{min} or more',
        'min': 'The {field} field must be at least 0:{length} characters',
        'numeric': 'The {field} field may only contain numeric characters',
        'one_of': 'The {field} field is not a valid value',
        'regex': 'The {field} field format is invalid',
        'required_if': 'The {field} field is required',
        'required': 'The {field} field is required',
        'size': 'The {field} field size must be less than 0:{size}KB',
        // Custom
        'json_object': 'The {field} must be a valid JSON object',
        'date': 'The {field} must be a valid Date',
        'datetime': 'The {field} must be a valid Date',
        'string_length': 'The {field} field must be exactly 0:{length} characters',
        // Custom + List
        'list_required': 'The {field} field is required',
        'list_length': 'The {field} field must have exactly 0:{list_length} items',
        'list_min': 'The {field} field must have at least 0:{list_min} items',
        'list_max': 'The {field} field may not have more than 0:{list_max} items'
    }
};

export const el = {
    'code': 'el',
    'messages': {
        'alpha': 'Το πεδίο "{field}" πρέπει να περιέχει μόνο αλφαβητικούς χαρακτήρες',
        'alpha_num': 'Το πεδίο "{field}" πρέπει να περιέχει μόνο αλφαριθμητικούς χαρακτήρες',
        'alpha_dash': 'Το πεδίο "{field}" μπορεί να περιέχει αλφαριθμητικούς χαρακτήρες, παύλες και κάτω παύλες',
        'alpha_spaces': 'Το πεδίο "{field}" μπορεί να περιέχει μόνο αλφαβητικούς χαρακτήρες και κενά',
        'between': 'Το πεδίο "{field}" πρέπει να είναι μεταξύ 0:{min} καί 1:{max}',
        'confirmed': 'Το πεδίο "{field}" δεν ταιριάζει με {target}',
        'digits': 'Το πεδίο "{field}" πρέπει να είναι αριθμός και να περιέχει 0:{length} ψηφία',
        'dimensions': 'Το πεδίο "{field}" πρέπει να είναι 0:{width} pixels επί 1:{height} pixels',
        'email': 'Το πεδίο "{field}" πρέπει να είναι έγκυρο email',
        'excluded': 'Το πεδίο "{field}" πρέπει να είναι έγκυρη τιμή',
        'ext': 'Το πεδίο "{field}" πρέπει να είναι έγκυρο αρχείο',
        'image': 'Το πεδίο "{field}" πρέπει να είναι εικόνα',
        'integer': 'Το πεδίο "{field}" πρέπει να είναι ακέραιος αριθμός',
        'length': 'Το πεδίο "{field}" πρέπει να είναι 0:{length} χαρακτήρες',
        'max_value': 'Το πεδίο "{field}" πρέπει να είναι 0:{max} ή λιγότερο',
        'max': 'Το πεδίο "{field}" δεν πρέπει να υπερβαίνει τους 0:{length} χαρακτήρες',
        'mimes': 'Το πεδίο "{field}" πρέπει να είναι έγκυρο αρχείο ΜΙΜΕ',
        'min_value': 'Το πεδίο "{field}" πρέπει να είναι 0:{min} ή περισσότερο',
        'min': 'Το πεδίο "{field}" πρέπει να είναι τουλάχιστον 0:{length} χαρακτήρες',
        'numeric': 'Το πεδίο "{field}" πρέπει να περιέχει μόνο αριθμούς',
        'one_of': 'Το πεδίο "{field}" πρέπει να είναι έγκυρη τιμή',
        'regex': 'Το πεδίο "{field}" δεν είναι έγκυρο',
        'required': 'Το πεδίο "{field}" δεν έχει συμπληρωθεί',
        'required_if': 'Το πεδίο "{field}" δεν έχει συμπληρωθεί',
        'size': 'Το πεδίο "{field}" πρέπει να μην υπερβαίνει τα 0:{size}KB',
        // Custom
        'json_object': 'Το πεδίο {field} πρέπει να είναι ένα έγκυρο JSON αντικείμενο',
        'date': 'Το πεδίο {field} πρέπει να είναι μία έγκυρη Ημερομηνία',
        'datetime': 'Το πεδίο {field} πρέπει να είναι μία έγκυρη Ημερομηνία',
        'string_length': 'Το πεδίο "{field}" πρέπει να είναι ακριβώς 0:{list_length} χαρακτήρες',
        // Custom + List
        'list_required': 'Το πεδίο "{field}" δεν έχει συμπληρωθεί',
        'list_length': 'Το πεδίο "{field}" πρέπει να έχει ακριβώς 0:{list_length} αντικείμενα',
        'list_min': 'Το πεδίο {field} πρέπει να έχει τουλάχιστον 0:{list_min} αντικείμενα',
        'list_max': 'Το πεδίο {field} πρέπει να έχει το πολύ 0:{list_max} αντικείμενα'
    }
};
