<template>
    <div class="CommonContainerWidthControl relative max-w-full sm:max-w-7xl md:max-w-6xl lg:max-w-5xl xl:max-w-4xl 2xl:max-w-3xl">
        <slot></slot>
    </div>
</template>

<script>
/**
 * Width control for CommonContainer component..
 *
 * @author Dimitris Gkoulis
 * @createdAt 27 February 2021
 */
export default {
    name: 'CommonContainerWidthControl'
};
</script>
