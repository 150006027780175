import { onBeforeMount, onBeforeUnmount } from 'vue';
import { EventBusService } from '@/core/service';

/**
 * Enables an event handler while a component is "active".
 *
 * @author Dimitris Gkoulis
 * @createdAt 5 February 2021
 * @future IMPORTANT (potential bug) (Thursday 30 December 2021):
 *  When the resource changes without view unmount the `unsubscribe` MUST BE CALLED MANUALLY!
 *  However, the way that this composable works ensures that the handler is always the same but its references change.
 *  So, there is a possibility that this is NOT a bug... I have to look further on it.
 */
export default function useSimpleEphemeralEventHandler (key, handler) {
    onBeforeMount(() => {
        // EventBusService.subscribe(key, () => {
        //     handler();
        // });
        EventBusService.subscribe(key, handler);
    });
    onBeforeUnmount(() => {
        // EventBusService.unsubscribe(key, () => {
        //     handler();
        // });
        EventBusService.unsubscribe(key, handler);
    });
}
