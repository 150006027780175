/****************************************************************************************************
 * Polyglot Persistence (PPA specification) basic API Client.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt Monday 1 November 2021
 * @lastModifiedAt never
 ****************************************************************************************************/

import ApiUrlProvider from '../api-url-provider';
import BaseApiClient from '../base-api-client';

function kernelPPAVersionedApi(path) {
    return ApiUrlProvider.kernelVersionedApi(`ppa/${path}`);
}

const PolyglotPersistenceBasicApiClient = {
    createEntitySpace (data) {
        const url = kernelPPAVersionedApi('entity-spaces');
        return BaseApiClient.post(url, data);
    },
    updateEntitySpace (data) {
        const url = kernelPPAVersionedApi('entity-spaces');
        return BaseApiClient.put(url, data);
    },
    getEntitySpace (entitySpaceName) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}`);
        return BaseApiClient.get(url);
    },
    deleteEntitySpace (entitySpaceName) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}`);
        return BaseApiClient.delete(url);
    },
    getAllEntitySpaces (page = 0, size = 10, sortField = 'id', sortDirection = 'desc') {
        const url = kernelPPAVersionedApi(`entity-spaces`);
        return BaseApiClient.get(url, {
            params: {
                page: page,
                size: size,
                sort: sortField + ',' + sortDirection
            }
        });
    },

    createEntityType (entitySpaceName, data) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types`);
        return BaseApiClient.post(url, data);
    },
    updateEntityType (entitySpaceName, data) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types`);
        return BaseApiClient.put(url, data);
    },
    getEntityType (entitySpaceName, entityTypeName) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}`);
        return BaseApiClient.get(url);
    },
    deleteEntityType (entitySpaceName, entityTypeName) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}`);
        return BaseApiClient.delete(url);
    },
    getAllEntityTypes (entitySpaceName, page = 0, size = 10, sortField = 'id', sortDirection = 'desc') {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types`);
        return BaseApiClient.get(url, {
            params: {
                page: page,
                size: size,
                sort: sortField + ',' + sortDirection
            }
        });
    },

    updateSchemaDefinitionDisplayOrders (entitySpaceName, entityTypeName, data) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}/display-orders`);
        return BaseApiClient.post(url, data);
    },

    savePropertyGroup (entitySpaceName, entityTypeName, data) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}/property-groups`);
        return BaseApiClient.post(url, data);
    },
    deletePropertyGroup (entitySpaceName, entityTypeName, propertyGroupName) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}/property-groups/${propertyGroupName}`);
        return BaseApiClient.delete(url);
    },

    createPropertyDefinition (entitySpaceName, entityTypeName, data) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}/property-definitions`);
        return BaseApiClient.post(url, data);
    },
    updatePropertyDefinition (entitySpaceName, entityTypeName, data) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}/property-definitions`);
        return BaseApiClient.put(url, data);
    },
    deletePropertyDefinition (entitySpaceName, entityTypeName, propertyDefinitionName) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}/property-definitions/${propertyDefinitionName}`);
        return BaseApiClient.delete(url);
    },

    /* Combines: PropertyDefinition + RelationDefinition = Relation */

    createRelation (entitySpaceName, entityTypeName, data) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}/relations`);
        return BaseApiClient.post(url, data);
    },
    updateRelation (entitySpaceName, entityTypeName, data) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}/relations`);
        return BaseApiClient.put(url, data);
    },
    deleteRelation (entitySpaceName, entityTypeName, relationName) {
        // relationName aka propertyDefinitionName.
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}/relations/${relationName}`);
        return BaseApiClient.delete(url);
    },

    createEntity (entitySpaceName, entityTypeName, data, aggregate = false) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}/entities`);
        return BaseApiClient.post(url, data, {
            params: {
                aggregate: aggregate
            }
        });
    },
    updateEntity (entitySpaceName, entityTypeName, data, aggregate = false) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}/entities`);
        return BaseApiClient.put(url, data, {
            params: {
                aggregate: aggregate
            }
        });
    },
    getEntity (entitySpaceName, entityTypeName, entityId, aggregate = false) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}/entities/${entityId}`);
        return BaseApiClient.get(url, {
            params: {
                aggregate: aggregate
            }
        });
    },
    deleteEntity (entitySpaceName, entityTypeName, entityId) {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}/entities/${entityId}`);
        return BaseApiClient.delete(url);
    },
    getAllEntities (entitySpaceName, entityTypeName, aggregate = false, page = 0, size = 10, sortField = 'id', sortDirection = 'desc') {
        const url = kernelPPAVersionedApi(`entity-spaces/${entitySpaceName}/entity-types/${entityTypeName}/entities`);
        return BaseApiClient.get(url, {
            params: {
                aggregate: aggregate,
                page: page,
                size: size,
                sort: sortField + ',' + sortDirection
            }
        });
    }
};

export default PolyglotPersistenceBasicApiClient;
