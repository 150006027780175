<template>
    <div class="CommonContainer relative mx-auto px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
        <slot></slot>
    </div>
</template>

<script>
/**
 * Common container.
 *
 * @author Dimitris Gkoulis
 * @createdAt 12 January 2021
 * @lastModifiedAt 27 February 2021
 */
export default {
    name: 'CommonContainer'
};
</script>

<style>
.CommonContainer {
    max-width: 1420px;
}
@media only screen and (min-width: 1920px) {
    .CommonContainer {
        margin-left: 60px;
    }
}
</style>
