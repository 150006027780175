/****************************************************************************************************
 * VeeValidate plugin configuration and custom rules declarion.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 18 January 2021
 * @lastModifiedAt 24 February 2021
 ****************************************************************************************************/

import { configure, defineRule } from 'vee-validate';
import {
    alpha, alpha_dash, alpha_num, alpha_spaces,
    between,
    confirmed,
    digits,
    dimensions,
    email,
    ext,
    image,
    integer,
    is, is_not,
    length,
    max, max_value,
    mimes,
    min, min_value,
    not_one_of,
    numeric,
    one_of,
    regex,
    required,
    size
} from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import { DateUtils } from '@/core/utils';
import { en, el } from './vee-validate-locale';

function defineBuiltInRulesGlobally () {
    defineRule('alpha', alpha);
    defineRule('alpha_dash', alpha_dash);
    defineRule('alpha_num', alpha_num);
    defineRule('alpha_spaces', alpha_spaces);
    defineRule('between', between);
    defineRule('confirmed', confirmed);
    defineRule('digits', digits);
    defineRule('dimensions', dimensions);
    defineRule('email', email);
    defineRule('ext', ext);
    defineRule('image', image);
    defineRule('integer', integer);
    defineRule('is', is);
    defineRule('is_not', is_not);
    defineRule('length', length);
    defineRule('max', max);
    defineRule('max_value', max_value);
    defineRule('mimes', mimes);
    defineRule('min', min);
    defineRule('min_value', min_value);
    defineRule('not_one_of', not_one_of);
    defineRule('numeric', numeric);
    defineRule('one_of', one_of);
    defineRule('regex', regex);
    defineRule('required', required);
    defineRule('size', size);
}

function defineCustomRulesGlobally () {
    defineRule('json_object', function (value) {
        if (!value) {
            return true;
        }

        try {
            const o = JSON.parse(value);
            return !!(o && typeof o === 'object');
        } catch (ignoredError) {
            return false;
        }
    });

    defineRule('date', function (value) {
        if (!value) {
            return true;
        }
        return DateUtils.isValidDate(value);
    });
    defineRule('datetime', function (value) {
        if (!value) {
            return true;
        }
        return DateUtils.isValidDate(value);
    });

    defineRule('string_length', (value, [limit]) => {
        // The field is empty so it should pass
        if (!value || !value.length) {
            return true;
        }
        return value.length === limit;
    });

    defineRule('list_required', (value) => {
        if (value === null || value === undefined) return false;
        if (!Array.isArray(value)) return false;
        return value.length > 0;
    });
    defineRule('list_length', (value, [limit]) => {
        // The field is empty so it should pass
        if (!value || !value.length) {
            return true;
        }
        return value.length === limit;
    });
    defineRule('list_min', (value, [limit]) => {
        // The field is empty so it should pass
        if (!value || !value.length) {
            return true;
        }
        return value.length >= limit;
    });
    defineRule('list_max', (value, [limit]) => {
        // The field is empty so it should pass
        if (!value || !value.length) {
            return true;
        }
        return value.length <= limit;
    });
}

const VeeValidatePlugin = {
    initialize () {
        defineBuiltInRulesGlobally();
        defineCustomRulesGlobally();
        configure({
            generateMessage: localize({
                en: en,
                el: el
            })
        });
    },
    defineRule (id, validator) {
        defineRule(id, validator);
    },
    setLocale (languageKey) {
        setLocale(languageKey);
    }
};

export default VeeValidatePlugin;
