<template>
    <!--
    Enable layout with always visible header and footer and scrollable body.
    Add class="relative flex flex-col overflow-y-auto" style="height: 500px;" to the wrapper.
    Add class="flex-grow-0" to header and footer
    Add class="flex-grow flex-shrink overflow-y-auto" NOT 100% SURE ABOUT overflow-y-auto.
    -->
    <div class="relative flex flex-col overflow-y-auto w-full h-full">
        <!-- HEADER -->
        <div class="flex-grow-0 p-4 border-b">
            <div class="sm:flex sm:items-center sm:justify-between">
                <div class="mt-3 sm:mt-0 sm:flex sm:flex-row space-x-2">
                    <Button
                        v-if="displayCloseButton"
                        @click="handleCloseClick"
                        variation="white"
                    >
                        {{ $t('common_action_close') }}
                    </Button>
                    <Button
                        v-if="selectedStoredObjectCount > 0"
                        @click="handleSubmitSelectedStoredObjects"
                        variation="accent1"
                    >
                        {{ $t('common_action_select') }} <span class="bg-white px-1.5 rounded-full text-black mx-1">{{ selectedStoredObjectCount }}</span> {{ $tc('tc_select_File', selectedStoredObjectCount) }}
                    </Button>
                    <Button
                        v-if="selectedStoredObjectCount > 0"
                        @click="handleUnselectAllStoredObjects"
                        variation="white"
                        :title="$t('common_action_reset')"
                    >
                        <span class="sr-only">{{ $t('common_action_reset') }}</span>
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </Button>
                </div>
                <div class="mt-3 sm:mt-0 sm:flex sm:flex-row space-x-2">
                    <div>
                        <label for="titleTerm" class="sr-only">{{ $t('common_action_search') }}</label>
                        <div class="flex rounded-md shadow-sm">
                            <div class="relative flex-grow focus-within:z-10">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <input
                                    :value="titleTerm"
                                    @input="changeTitleTerm"
                                    type="text"
                                    name="titleTerm"
                                    id="titleTerm"
                                    class="focus:ring-blue-500 focus:border-blue-500 w-full rounded pl-10 block sm:text-sm border-gray-300"
                                    :placeholder="$t('common_action_search') + '...'"
                                />
                            </div>
                        </div>
                    </div>
                    <Button
                        @click="switchDisplayUpload"
                        variation="white"
                        :title="$t('common_action_upload')"
                    >
                        <span class="sr-only">{{ $t('common_action_upload') }}</span>
                        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                        </svg>
                    </Button>
                </div>
            </div>
        </div>

        <!-- BODY -->
        <div class="flex-grow flex-shrink overflow-y-auto p-4">
            <!-- UPLOAD -->
            <div
                v-show="displayUpload"
                class="mb-4"
            >
                <StoredObjectExplorerUpload
                    v-on:upload="handleUpload"
                />
            </div>
            <!-- GRID -->
            <!-- Another idea: 2 - 3 - 5 - 8 - 11 -->
            <div class="mb-4 grid grid-flow-col grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-10 grid-flow-row-dense gap-4">
                <div
                    v-for="storedObject in storedObjectList"
                    :key="storedObject.id"
                    class="relative flex flex-col border rounded shadow-sm cursor-pointer"
                    :class="{ 'border-blue-200': !!selectedStoredObjectMap[storedObject.id] }"
                    @click="handleClickOnStoredObjectBasedOnSelectionType(storedObject)"
                >
                    <p
                        v-if="!!recentStoredObjectMap[storedObject.id]"
                        class="absolute top-2 left-2 bg-blue-500 shadow text-white px-1 py-1 text-xs font-semibold uppercase"
                    >
                        {{ $t('Recent') }}
                    </p>
                    <div class="flex-grow p-2 inline-flex flex-col justify-center">
                        <img
                            v-if="storedObject.isImage"
                            class="mx-auto w-auto h-auto max-h-40"
                            :src="storedObject.adminThumbUrl"
                        />
                        <div
                            v-else
                            class="mx-auto w-auto h-auto max-h-40"
                        >
                            <svg class="w-8 h-8 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                        </div>
                    </div>
                    <div
                        class="p-2 flex-none border-t"
                        :class="{ 'bg-blue-50': !!selectedStoredObjectMap[storedObject.id] }"
                    >
                        <p class="text-sm truncate" :title="storedObject.title">{{ storedObject.title }}</p>
                        <p
                            v-if="storedObject.title !== storedObject.fileName"
                            class="text-xs truncate text-gray-500"
                            :title="storedObject.fileName"
                        >
                            {{ storedObject.fileName }}
                        </p>
                    </div>
                </div>
            </div>
            <!-- PAGINATION -->
            <div class="text-center">
                <Button
                    v-if="hasMoreStoredObjectEntities"
                    :loading="storedObjectListLoading"
                    variation="white"
                    @click="loadMoreStoredObjectEntities"
                >
                    {{ $t('Load more') }}
                </Button>
            </div>
        </div>

        <!-- FOOTER -->
        <div class="flex-grow-0 p-4 border-t">
            <div class="text-center">
                <p class="text-xs text-gray-500 font-semibold">{{ $t('pagination_load_more_showing_of', { listCount: storedObjectList.length, totalCount: storedObjectTotalCount }) }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, ref, onMounted, toRefs, onBeforeMount } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import uniqWith from 'lodash/uniqWith';
import { GlobalCdnUrlProvider, StorageStoredObjectBasicApiClient } from '@/core/api';
import StoredObjectExplorerUpload from './StoredObjectExplorerUpload';

const IMAGE_MIME_TYPE_LIST = ['image/png', 'image/jpg', 'image/jpeg'];

function processStoredObject (storedObject) {
    storedObject['isImage'] = IMAGE_MIME_TYPE_LIST.indexOf(storedObject['mimeType']) !== -1;
    storedObject['adminThumbUrl'] = GlobalCdnUrlProvider.thumbFromUrl(storedObject['url'], '300x300');
    return storedObject;
}

/**
 * A component for exploring and managing StoredObject entities.
 *
 * @future implementations:
 * - processStoredObject : reduce the size of each object. Keep only the necessary information.
 * - add a max limit of elements that can be rendered (performance!).
 * - allow or not: upload
 * - allow selection for files that have a specific type or/and extension
 * - allow selection while loading?
 * - max selections (limitation)
 *
 * @author Dimitris Gkoulis
 * @createdAt 22 February 2021
 * @lastModifiedAt 30 April 2021
 */
export default {
    name: 'StoredObjectExplorer',
    components: {
        StoredObjectExplorerUpload
    },
    props: {
        selectType: {
            type: String,
            default: 'none',
            validator: function (value) {
                return ['none', 'single', 'multiple'].indexOf(value) !== -1;
            },
            required: false
        },
        initialValueForSingle: null, // EXPERIMENTAL.
        initialValuesForMultiple: null, // EXPERIMENTAL.
        displayCloseButton: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    emits: [
        'select-single',
        'select-multiple',
        'close'
    ],
    setup (props, { emit }) {
        const {
            selectType,
            initialValueForSingle,
            initialValuesForMultiple
        } = toRefs(props);

        const recentStoredObjectMap = ref({});

        const selectedStoredObjectMap = ref({});
        const handleClickOnStoredObjectBasedOnSelectionType = (storedObject) => {
            if (selectType.value === 'none') return;
            if (storedObject === null || storedObject === undefined) return; // Necessary for programmatic calls.
            if (selectType.value === 'single') {
                if (Object.prototype.hasOwnProperty.call(selectedStoredObjectMap.value, storedObject.id)) {
                    // Unselect.
                    delete selectedStoredObjectMap.value[storedObject.id];
                } else {
                    selectedStoredObjectMap.value = {}; // Remove.
                    // Select (processing is necessary for initial data).
                    selectedStoredObjectMap.value[storedObject.id] = processStoredObject(cloneDeep(storedObject));
                    selectedStoredObjectMap.value[storedObject.id]['_selectedAt'] = new Date().getTime();
                }
                return;
            }
            if (Object.prototype.hasOwnProperty.call(selectedStoredObjectMap.value, storedObject.id)) {
                // Unselect.
                delete selectedStoredObjectMap.value[storedObject.id];
            } else {
                // Select (processing is necessary for initial data).
                selectedStoredObjectMap.value[storedObject.id] = processStoredObject(cloneDeep(storedObject));
                selectedStoredObjectMap.value[storedObject.id]['_selectedAt'] = new Date().getTime();
            }
        };
        const selectedStoredObjectCount = computed(() => {
            return Object.keys(selectedStoredObjectMap.value).length;
        });
        const handleSubmitSelectedStoredObjects = () => {
            const values = Object.values(selectedStoredObjectMap.value);
            if (values.length <= 0) return;
            if (selectType.value === 'single') {
                emit('select-single', cloneDeep(values[0]));
            } else if (selectType.value === 'multiple') {
                emit('select-multiple', cloneDeep(values).sort((a, b) => a._selectedAt - b._selectedAt));
            }
        };
        const handleUnselectAllStoredObjects = () => {
            selectedStoredObjectMap.value = {};
        };

        onBeforeMount(() => {
            // Use functionality to programmatically add the initial value(s) (if any).
            if (selectType.value === 'single') {
                if (initialValueForSingle && initialValueForSingle.value) {
                    handleClickOnStoredObjectBasedOnSelectionType(initialValueForSingle.value);
                }
            } else if (selectType.value === 'multiple') {
                if (initialValuesForMultiple && Array.isArray(initialValuesForMultiple.value)) {
                    for (const temp of initialValuesForMultiple.value) {
                        handleClickOnStoredObjectBasedOnSelectionType(temp);
                    }
                }
            }
        });

        const previousStoredObjectList = ref([]);
        const previousStoredObjectTotalCount = ref(0);
        const previousStoredObjectListingPage = ref(0);

        const titleTerm = ref('');
        const storedObjectListingPage = ref(0);
        const storedObjectListingSize = ref(25);
        const storedObjectListLoading = ref(false);
        const storedObjectError = ref(null);
        const storedObjectList = ref([]);
        const storedObjectTotalCount = ref(0);

        // appends to existing list.
        const getStoredObjectList = async (reset = false) => {
            if (reset === true) {
                storedObjectListingPage.value = 0;
            }
            storedObjectListLoading.value = true;
            storedObjectError.value = null;
            await StorageStoredObjectBasicApiClient.getAllStoredObjects(titleTerm.value, storedObjectListingPage.value, storedObjectListingSize.value)
                .then((data) => {
                    // This helps the UI to remain with the previous listing until the new one is fetched and rendered.
                    if (reset === true) {
                        storedObjectList.value = data.data.map(processStoredObject);
                    } else {
                        storedObjectList.value = storedObjectList.value.concat(data.data.map(processStoredObject));
                    }
                    storedObjectTotalCount.value = parseInt(data.headers['x-total-count'], 10);

                    storedObjectList.value = uniqWith(storedObjectList.value, function (arrVal, othVal) {
                        return arrVal.id === othVal.id;
                    });

                    // Backup all.
                    if (titleTerm.value === '') {
                        previousStoredObjectList.value = cloneDeep(storedObjectList.value);
                        previousStoredObjectTotalCount.value = storedObjectTotalCount.value;
                        previousStoredObjectListingPage.value = storedObjectListingPage.value;
                    }
                })
                .catch((reason) => {
                    storedObjectList.value = [];
                    storedObjectError.value = reason;
                })
                .finally(() => {
                    storedObjectListLoading.value = false;
                });
        };

        const loadMoreStoredObjectEntities = () => {
            storedObjectListingPage.value++;
            getStoredObjectList();
        };
        const hasMoreStoredObjectEntities = computed(() => {
            if (storedObjectListLoading.value === true) return false; // We don't know yet. Also stops the button flashing.
            return storedObjectList.value.length < storedObjectTotalCount.value;
        });

        const displayUpload = ref(false);
        const switchDisplayUpload = () => {
            displayUpload.value = !displayUpload.value;
        };

        onMounted(() => getStoredObjectList(true));

        const changeTitleTerm = debounce(function ($event) {
            let value = $event.target.value;
            if (typeof value !== 'string') {
                titleTerm.value = '';
            }
            titleTerm.value = value.trim();
            if (titleTerm.value === '') {
                storedObjectListLoading.value = true;
                // Do not fetch all these items again. Get all backup-ed data.
                storedObjectList.value = cloneDeep(previousStoredObjectList.value);
                storedObjectTotalCount.value = previousStoredObjectTotalCount.value;
                storedObjectListingPage.value = previousStoredObjectListingPage.value;
                storedObjectListLoading.value = false;
            } else {
                getStoredObjectList(true);
            }
        }, 500);

        const handleUpload = (storedObject) => {
            storedObject['isRecent'] = true; // Not working properly. Why? I think it's just fine.
            recentStoredObjectMap.value[storedObject.id] = true;
            previousStoredObjectList.value.unshift(processStoredObject(cloneDeep(storedObject)));
            previousStoredObjectTotalCount.value++;
            storedObjectList.value.unshift(processStoredObject(cloneDeep(storedObject)));
        };

        const handleCloseClick = () => {
            emit('close');
        };

        return {
            recentStoredObjectMap,
            selectedStoredObjectMap,
            handleClickOnStoredObjectBasedOnSelectionType,
            selectedStoredObjectCount,
            handleSubmitSelectedStoredObjects,
            handleUnselectAllStoredObjects,
            titleTerm,
            storedObjectListLoading,
            storedObjectList,
            storedObjectTotalCount,
            loadMoreStoredObjectEntities,
            hasMoreStoredObjectEntities,
            displayUpload,
            switchDisplayUpload,
            changeTitleTerm,
            handleUpload,
            handleCloseClick
        };
    }
};
</script>
