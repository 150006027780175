<template>
    <div
        v-if="storedObjectInstance"
        class="relative flex flex-col border rounded shadow-sm"
        :class="{
            'cursor-pointer': allowClick,
            'cursor-move': allowDrag
        }"
    >
        <p
            v-if="displayRecent && storedObjectInstance.isRecent"
            class="absolute top-2 left-2 bg-blue-500 shadow text-white px-1 py-1 text-xs font-semibold uppercase"
        >
            {{ $t('Recent') }}
        </p>
        <button
            v-if="displayRemove"
            @click="removeClicked"
            class="absolute top-4 right-4 bg-gray-50 shadow hover:shadow-md rounded-full p-2 inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
            <span class="sr-only">{{ $t('common_action_remove') }}</span>
            <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
        </button>
        <div
            @click="itemClicked"
            class="flex-grow p-2 inline-flex flex-col justify-center"
        >
            <img
                v-if="storedObjectInstance.isImage"
                class="mx-auto w-auto h-auto max-h-40"
                :src="storedObjectInstance.adminThumbUrl"
            />
            <div
                v-else
                class="mx-auto w-auto h-auto max-h-40"
            >
                <svg class="w-8 h-8 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
            </div>
        </div>
        <div
            @click="itemClicked"
            class="p-2 flex-none border-t"
        >
            <p class="text-sm truncate" :title="storedObjectInstance.title">{{ storedObjectInstance.title }}</p>
            <p
                v-if="storedObjectInstance.title !== storedObjectInstance.fileName"
                class="text-xs truncate text-gray-500"
                :title="storedObjectInstance.fileName"
            >
                {{ storedObjectInstance.fileName }}
            </p>
        </div>
    </div>
</template>
<script>
import { toRefs } from 'vue';
import { GlobalCdnUrlProvider } from '@/core/api';

const IMAGE_MIME_TYPE_LIST = ['image/png', 'image/jpg', 'image/jpeg'];

/**
 * A component for displaying a StoredObject entity in listings, pickers, etc.
 *
 * @author Dimitris Gkoulis
 * @createdAt 22 February 2021
 * @lastModifiedAt 24 February 2021
 */
export default {
    name: 'StoredObjectItem',
    props: {
        storedObject: {
            type: Object,
            required: true
        },
        displayRecent: {
            type: Boolean,
            default: false
        },
        displayRemove: {
            type: Boolean,
            default: false
        },
        allowClick: {
            type: Boolean,
            default: false
        },
        allowDrag: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'item-clicked',
        'remove-clicked'
    ],
    setup (props, { emit }) {
        const { storedObject } = toRefs(props);

        if (storedObject.value === null) {
            return {
                storedObjectInstance: null,
                itemClicked: () => (void 0),
                removeButtonClicked: () => (void 0)
            };
        }

        // Process.
        if (typeof storedObject.value.isImage !== 'boolean') {
            storedObject.value['isImage'] = IMAGE_MIME_TYPE_LIST.indexOf(storedObject.value['mimeType']) !== -1;
        }
        if (typeof storedObject.value.isRecent !== 'boolean') {
            storedObject.value['isRecent'] = false;
            const a = new Date(storedObject.value.meta.createdDate).getTime();
            const b = new Date();
            const minutes = Math.floor((b - a) / 60000);
            if (minutes <= 10) {
                storedObject.value['isRecent'] = true;
            }
        }
        if (typeof storedObject.value.adminThumbUrl !== 'string') {
            storedObject.value['adminThumbUrl'] = GlobalCdnUrlProvider.thumbFromUrl(storedObject.value.url, '300x300');
        }

        const itemClicked = () => {
            emit('item-clicked', storedObject.value);
        };
        const removeClicked = () => {
            emit('remove-clicked', storedObject.value);
        };

        return {
            storedObjectInstance: storedObject,
            itemClicked,
            removeClicked
        };
    }
};
</script>

<style>
.StoredObjectItem--DnD--Ghost {
    @apply bg-blue-50;
}
.StoredObjectItem--DnD--Drag {
    @apply bg-blue-50 border border-blue-300;
}
</style>
