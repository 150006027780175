<template>
    <div class="md:flex md:items-center md:justify-between">
        <div class="flex-1 min-w-0">
            <p class="mb-0.5 sm:mb-1 text-xs text-gray-600 font-medium" v-if="subtitle">{{ subtitle }}</p>
            <h2 class="text-2xl font-bold leading-7 text-gray-900 dark:text-gray-100 sm:text-3xl sm:truncate">
                <slot name="title"></slot>
            </h2>
        </div>
        <div class="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
/**
 * Common header.
 *
 * @author Dimitris Gkoulis
 * @createdAt 12 January 2021
 * @lastModifiedAt 27 February 2021
 */
export default {
    name: 'CommonHeader',
    props: {
        subtitle: String
    }
};
</script>
