/****************************************************************************************************
 * Vue.js Router Utilities.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 14 May 2021
 ****************************************************************************************************/

const RouterUtils = {};

RouterUtils.scrollBehaviorToBody = function (to, from) {
    if (to !== null && to !== undefined && from !== null && from !== undefined) {
        if (to.name !== from.name) {
            const element = document.body;
            if (element !== null) {
                element.scrollTop = 0;
            }
        }
    }
    return {
        top: 0,
        left: 0
    };
};

RouterUtils.scrollBehaviorToMainArea = function (to, from) {
    if (to !== null && to !== undefined && from !== null && from !== undefined) {
        if (to.name !== from.name) {
            const element = document.getElementById('main-area');
            if (element !== null) {
                element.scrollTop = 0;
            }
        }
    }
    return {
        top: 0,
        left: 0
    };
};

export default RouterUtils;
