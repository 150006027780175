<template>
    <div class="CommonContainerPseudo relative">
        <slot></slot>
    </div>
</template>

<script>
/**
 * Common PSEUDO container.
 *
 * @author Dimitris Gkoulis
 * @createdAt 15 April 2021
 */
export default {
    name: 'CommonContainerPseudo'
};
</script>
