<template>
    <Field
        :name="name"
        :value="modelValue"
        @input="onInput"
        :validate-on-blur="validateOnBlur"
        :validate-on-change="validateOnChange"
        :validate-on-input="validateOnInput"
        :validate-on-model-update="validateOnModelUpdate"
        v-slot="{ field }"
    >
        <input
            v-bind="field"
            type="hidden"
            :id="name"
        />
    </Field>
</template>
<script>
import { Field } from 'vee-validate';

/**
 * Hidden input pattern based on VeeValidate.
 *
 * @author Dimitris Gkoulis
 * @createdAt 19 January 2021
 * @lastModifiedAt 19 February 2021
 */
export default {
    name: 'VvInputHidden',
    inheritAttrs: false,
    components: {
        Field
    },
    emits: [
        'update:modelValue',
        'update-batch'
    ],
    props: {
        name: {
            type: String,
            required: true
        },
        modelValue: null,
        // FormVueLate + VeeValidate specifics.
        validateOnBlur: {
            type: Boolean,
            default: true
        },
        validateOnChange: {
            type: Boolean,
            default: true
        },
        validateOnInput: {
            type: Boolean,
            default: true
        },
        validateOnModelUpdate: {
            type: Boolean,
            default: true
        },
        validation: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        onInput (event) {
            this.$emit('update:modelValue', event.target.value);
        }
    }
};
</script>
