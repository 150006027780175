/****************************************************************************************************
 * Manages axios instances and default configurations.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 12 January 2021
 ****************************************************************************************************/

import axios from 'axios';

const defaultAxiosInstance = axios.create();

defaultAxiosInstance.defaults.withCredentials = true;
defaultAxiosInstance.defaults.crossDomain = true;

const ApiClient = {
    getDefaultAxiosInstance () {
        return defaultAxiosInstance;
    }
};

export default ApiClient;
