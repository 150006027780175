/****************************************************************************************************
 * Reusable common props for VeeValidate.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 21 February 2021
 ****************************************************************************************************/

export default function veeValidateProps () {
    return {
        validateOnBlur: {
            type: Boolean,
            default: true
        },
        validateOnChange: {
            type: Boolean,
            default: true
        },
        validateOnInput: {
            type: Boolean,
            default: true
        },
        validateOnModelUpdate: {
            type: Boolean,
            default: true
        },
        /*
        // Controlled by the form component itself.
        validateOnMount: {
            type: Boolean,
            default: false
        },
        */
        validation: {
            type: Object,
            default: () => ({})
        }
    };
}
