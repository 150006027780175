/****************************************************************************************************
 * Application store module.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 29 January 2021
 * @lastModifiedAt 13 May 2021
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

const state = {
    currentTenantId: null,

    // @future: IMPORTANT: Rename to userAccount and refactor!
    // (and distinct concepts: user vs userAccount)
    user: null,
    tenantAccount: null,

    tenantAccountList: [],

    initialized: false,
    initializedAndReady: false,

    userHasTenantUserAccessLevel: false,
    userHasTenantAdminAccessLevel: false
};

const getters = {
    currentTenantId (state) {
        return state.currentTenantId;
    },
    user (state) {
        return state.user;
    },
    tenantAccount (state) {
        return state.tenantAccount;
    },
    tenantAccountList (state) {
        return state.tenantAccountList;
    },
    initialized (state) {
        return state.initialized;
    },
    initializedAndReady (state) {
        return state.initializedAndReady;
    },
    userHasTenantUserAccessLevel (state) {
        return state.userHasTenantUserAccessLevel;
    },
    userHasTenantAdminAccessLevel (state) {
        return state.userHasTenantAdminAccessLevel;
    }
};

const actions = {
    async resetModule ({ commit }) {
        commit('setStateProp', { key: 'currentTenantId', value: null });

        commit('setStateProp', { key: 'user', value: null });
        commit('setStateProp', { key: 'tenantAccount', value: null });

        commit('setStateProp', { key: 'tenantAccountList', value: [] });

        commit('setStateProp', { key: 'initializedAndReady', value: false });

        commit('setStateProp', { key: 'userHasTenantUserAccessLevel', value: false });
        commit('setStateProp', { key: 'userHasTenantAdminAccessLevel', value: false });
    }
};

const mutations = {
    setStateProp (state, { key, value, clone = false }) {
        state[key] = clone === true ? cloneDeep(value) : value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
