<!--suppress HtmlFormInputWithoutLabel -->
<template>
    <label
        v-if="!!label"
        :for="name"
        class="VvLabel"
    >
        {{ label }} <span class="text-xs font-normal text-gray-400" v-if="optional">{{ optionalText }}</span>
    </label>
    <!--
    <button
        class="bg-red-400 text-white border-0 p-2 rounded shadow-md"
        @click="makeDateInvalid">
        Invalidate Date
    </button>
    -->
    <div :class="{ 'mt-1': !!label }">
        <!--suppress JSUnresolvedVariable -->
        <div
            :id="name"
            class="flex flex-row items-center space-x-2"
        >
            <select
                :tabindex="tabIndex"
                class="VvDateTimeInput__Select--Default"
                @change="editValue('day', $event)"
                ref="dayRef"
            >
                <option
                    v-for="i in daysInMonth"
                    :key="i"
                    :value="i"
                >
                    {{ addLeadingZeroToNumber(i) }}
                </option>
            </select>
            <select
                :tabindex="tabIndex + 0.2"
                class="VvDateTimeInput__Select--Default"
                @change="editValue('month', $event)"
                ref="monthRef"
            >
                <option
                    v-for="month in 12"
                    :key="month"
                    :value="month"
                >
                    {{ $t('i1_month_' + month) }}
                </option>
            </select>
            <select
                :tabindex="tabIndex + 0.4"
                class="VvDateTimeInput__Select--Default"
                @change="editValue('year', $event)"
                ref="yearRef"
            >
                <option
                    v-for="i in numberOfYears"
                    :key="i"
                    :value="startingYear + (i - 1)"
                >
                    {{ startingYear + (i - 1) }}
                </option>
            </select>
            <span v-if="displayTime" class="px-2">—</span>
            <select
                v-if="displayTime"
                :tabindex="tabIndex + 0.6"
                class="VvDateTimeInput__Select--Default"
                @change="editValue('hour', $event)"
                ref="hourRef"
            >
                <option
                    v-for="hour in 24"
                    :key="hour"
                    :value="(hour - 1)"
                >
                    {{ addLeadingZeroToNumber(hour - 1) }}
                </option>
            </select>
            <span v-if="displayTime">:</span>
            <select
                v-if="displayTime"
                :tabindex="tabIndex + 0.8"
                class="VvDateTimeInput__Select--Default"
                @change="editValue('minute', $event)"
                ref="minuteRef"
            >
                <option
                    v-for="minute in 60"
                    :key="minute"
                    :value="(minute - 1)"
                >
                    {{ addLeadingZeroToNumber(minute - 1) }}
                </option>
            </select>
        </div>
    </div>
    <div class="mt-2">
        <ErrorMessage
            :name="name"
            v-slot="{ message }"
        >
            <p class="VvErrorMessage__Text">{{ message }}</p>
        </ErrorMessage>
    </div>
    <div class="mt-2">
        <p v-if="!!helpText" class="VvHelpText--Default">{{ helpText }}</p>
    </div>
</template>
<script>
import { ref, onMounted, toRefs } from 'vue';
import { useField, ErrorMessage } from 'vee-validate';
import { DateUtils } from '@/core/utils';
import { I18nPlugin } from '@/core/plugins';
import veeValidateProps from './veeValidateProps';

const i18n = I18nPlugin.getDefaultI18nInstance();

const MAX_ALLOWED_YEAR = 9999;
const MIN_ALLOWED_YEAR = 0;

// Duplicate code : schemaDefinitionUtil.js (I want to keep this directory decoupled from SchemaDefinition).
function extractDate (val) {
    if (val === null || val === undefined) {
        return null;
    }
    if (val instanceof Date) {
        return val;
    }
    if (typeof val === 'number') {
        // Probably, milliseconds.
        return new Date(val);
    }
    if (typeof val === 'string') {
        // Check if it's numeric.
        if (!isNaN(val) && !isNaN(parseFloat(val))) {
            // Probably, milliseconds (numeric string).
            return new Date(Number(val)); // milliseconds.
        } else {
            // Probably, UTC.
            return new Date(val);
        }
    }
    return null;
}

function getCurrentYear () {
    return new Date().getFullYear();
}

function cloneDate (theDate) {
    if (DateUtils.isValidDate(theDate)) {
        return new Date(theDate.getTime());
    }
    return theDate;
}

/**
 * Date or/and Time input pattern based on VeeValidate.
 *
 * @future implementations:
 * - If it's not working with Form (like creators, editors, etc), move to SchemaDefinitionAsyncForm. Test it first.
 * - Blur behavior. In general, utilize flags, and implement on types of interactions (FormVueLate + VeeValidate, etc).
 *
 * @author Dimitris Gkoulis
 * @createdAt 21 February 2021
 */
export default {
    name: 'VvDateTimeInput',
    inheritAttrs: false,
    components: {
        ErrorMessage
    },
    emits: [
        'update:modelValue',
        'update-batch'
    ],
    props: {
        name: {
            type: String,
            required: true
        },
        label: String,
        optional: Boolean,
        optionalText: {
            type: String,
            default: function () {
                return i18n.global.t('Optional');
            }
        },
        helpText: String,
        suffix: String,
        startWithCurrentDate: {
            type: Boolean,
            default: false
        },
        modelValue: null, // Expected to be Date or null.
        type: {
            type: String,
            default: 'date',
            validator: function (value) {
                return ['date', 'datetime'].indexOf(value) !== -1;
            }
        },
        tabIndex: Number,
        // FormVueLate + VeeValidate specifics.
        ...veeValidateProps()
    },
    setup (props, { emit }) {
        // const { path, mapProps } = props._veeValidateConfig
        const { name, label, startWithCurrentDate, modelValue, type, validations } = toRefs(props);

        const displayTime = ref(false);
        if (type === 'datetime') {
            displayTime.value = true;
        }

        // This value is being manipulated multiple times on each user interaction with the UI controls.
        // Once the operations are finished, the value of this reference will be assigned to the VeeValidate 'value'
        // through the 'handleChange' method.
        const dateValueRef = ref(null);
        if (startWithCurrentDate.value === true) {
            dateValueRef.value = new Date();
        }

        let initialValue = modelValue ? modelValue.value : null;
        if (initialValue !== null) {
            // The right approach is for the parent component to provide the right type.
            // However due to heterogeneity this is not guaranteed.
            // For this reason this component tries the provided value into a JS date.
            const candidateDate = extractDate(initialValue);
            if (DateUtils.isValidDate(candidateDate)) {
                initialValue = cloneDate(candidateDate);
                dateValueRef.value = cloneDate(candidateDate);
            }
        }

        const {
            handleChange
        } = useField(name, validations, {
            initialValue: initialValue,
            label: label,
            type: Date
        });

        // UI details.
        const startingYear = 1900;
        const numberOfYears = 300;
        const daysInMonth = ref(31);

        // Elements.
        const dayRef = ref(null);
        const monthRef = ref(null);
        const yearRef = ref(null);
        const hourRef = ref(null);
        const minuteRef = ref(null);

        const synchronizeDaysInMonth = () => {
            if (DateUtils.isValidDate(dateValueRef.value)) {
                daysInMonth.value = DateUtils.getDaysInMonth(dateValueRef.value.getFullYear(), dateValueRef.value.getMonth());
            } else {
                const theDate = new Date();
                daysInMonth.value = DateUtils.getDaysInMonth(theDate.getFullYear(), theDate.getMonth());

                // Necessary in order to hide the "01" which is being displayed due to DOM change.
                if (dayRef.value !== null) {
                    setTimeout(() => {
                        dayRef.value.value = null;
                    }, 50);
                }
            }
        };

        const ensureDateInRangeForNewUnits = (year, month) => {
            const daysInTheMonth = DateUtils.getDaysInMonth(year, month);
            if (dateValueRef.value.getDate() > daysInTheMonth) {
                dateValueRef.value.setDate(daysInTheMonth);
            }
        };

        const ensureTime = () => {
            if (type.value !== 'datetime' && DateUtils.isValidDate(dateValueRef.value)) {
                dateValueRef.value.setHours(13);
                dateValueRef.value.setMinutes(0);
                dateValueRef.value.setSeconds(0);
                dateValueRef.value.setMilliseconds(0);
            }
        };

        const editValue = (unit, $event) => {
            // null date and invalid date are two different concepts.
            // As user interacts with the components, date may be invalid.
            // But it cannot be null. This statement ensure that dateValueRef value is not null.
            if (!(dateValueRef.value instanceof Date)) {
                dateValueRef.value = new Date();
            }

            if (unit === 'day') {
                let theDay = $event.target.value;
                dateValueRef.value.setDate(theDay);
            } else if (unit === 'month') {
                let theMonth = $event.target.value - 1;
                ensureDateInRangeForNewUnits(dateValueRef.value.getFullYear(), theMonth);
                dateValueRef.value.setMonth(theMonth);
            } else if (unit === 'year') {
                let theYear = $event.target.value;
                if (theYear > MAX_ALLOWED_YEAR || theYear <= MIN_ALLOWED_YEAR) {
                    theYear = getCurrentYear();
                }
                ensureDateInRangeForNewUnits(theYear, dateValueRef.value.getMonth());
                dateValueRef.value.setFullYear(theYear);
            } else if (unit === 'hour') {
                let theHour = $event.target.value;
                dateValueRef.value.setHours(theHour);
            } else if (unit === 'minute') {
                let theMinute = $event.target.value;
                dateValueRef.value.setHours(theMinute);
            } else {
                return;
            }

            ensureTime();
            synchronizeDaysInMonth();
            updateElements();
            handleChange(cloneDate(dateValueRef.value));
            emit('update:modelValue', cloneDate(dateValueRef.value));
        };

        const updateElements = () => {
            if (DateUtils.isValidDate(dateValueRef.value)) {
                dayRef.value.value = dateValueRef.value.getDate();
                monthRef.value.value = dateValueRef.value.getMonth() + 1;
                yearRef.value.value = dateValueRef.value.getFullYear();
                if (displayTime.value === true) {
                    hourRef.value.value = dateValueRef.value.getHours();
                    minuteRef.value.value = dateValueRef.value.getMinutes();
                }
            } else {
                dayRef.value.value = null;
                monthRef.value.value = null;
                yearRef.value.value = null;
                if (displayTime.value === true) {
                    hourRef.value.value = null;
                    minuteRef.value.value = null;
                }
            }
        };

        onMounted(() => {
            ensureTime();
            synchronizeDaysInMonth();
            updateElements();
            handleChange(cloneDate(dateValueRef.value));
            emit('update:modelValue', cloneDate(dateValueRef.value));
        });

        // For unit testing.
        const makeDateInvalid = () => {
            // noinspection JSValidateTypes
            dateValueRef.value = 'HA HA HA!';

            ensureTime();
            synchronizeDaysInMonth();
            updateElements();
            handleChange(cloneDate(dateValueRef.value));
            emit('update:modelValue', cloneDate(dateValueRef.value));
        };

        const addLeadingZeroToNumber = (num) => {
            if (num < 10) return '0' + num;
            return num;
        };

        return {
            displayTime,

            startingYear,
            numberOfYears,
            daysInMonth,

            dayRef,
            monthRef,
            yearRef,
            hourRef,
            minuteRef,

            editValue,
            makeDateInvalid,
            addLeadingZeroToNumber
        };
    }
};
</script>

<style>
.VvDateTimeInput__Select--Default {
    @apply mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md shadow-sm;
    @apply text-lg;
}
</style>
