/****************************************************************************************************
 * MS Websites "Page" resource API Client.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 16 February 2021
 * @lastModifiedAt Saturday 11 December 2021
 ****************************************************************************************************/

import ApiUrlProvider from '../api-url-provider';
import BaseApiClient from '../base-api-client';

const WebsitesPageBasicApiClient = {
    createPage (data) {
        const url = ApiUrlProvider.kernelVersionedApi('pages');
        return BaseApiClient.post(url, data);
    },
    updatePage (data) {
        const url = ApiUrlProvider.kernelVersionedApi('pages');
        return BaseApiClient.put(url, data);
    },
    getPage (id) {
        const url = ApiUrlProvider.kernelVersionedApi('pages/' + id);
        return BaseApiClient.get(url);
    },
    deletePage (id) {
        const url = ApiUrlProvider.kernelVersionedApi('pages/' + id);
        return BaseApiClient.delete(url);
    },
    getAllPagesByWebsiteId (
        websiteId, parentPageId = null, withParent = false,
        page = 0, size = 10,
        sortField = 'id', sortDirection = 'desc') {
        const url = ApiUrlProvider.kernelVersionedApi('pages');
        return BaseApiClient.get(url, {
            params: {
                websiteId: websiteId,
                parentPageId: parentPageId,
                withParent: withParent,
                page: page,
                size: size,
                sort: sortField + ',' + sortDirection
            }
        });
    }
};

export default WebsitesPageBasicApiClient;
