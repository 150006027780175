import headful from './headful';
import HeadMetaOptions from './head-meta-options';

const ON_DESTROY_STRATEGY_KEY = 'ods';
const ON_DESTROY_STRATEGY_CONFIG_OR_RESET_KEY = 'config_or_reset';
const ON_DESTROY_STRATEGY_RESET_KEY = 'reset';
const ON_DESTROY_STRATEGY_CONFIG_OR_EXCLUDE_KEY = 'config_or_exclude';
const ON_DESTROY_STRATEGY_DISABLE_KEY = 'disable';
const ON_DESTROY_STRATEGY_DEFAULT = ON_DESTROY_STRATEGY_CONFIG_OR_RESET_KEY; // If props or config is missing.
const OD_SUFFIX = 'Od';
const odStrategyKeys = [
    ON_DESTROY_STRATEGY_CONFIG_OR_RESET_KEY,
    ON_DESTROY_STRATEGY_RESET_KEY,
    ON_DESTROY_STRATEGY_CONFIG_OR_EXCLUDE_KEY,
    ON_DESTROY_STRATEGY_DISABLE_KEY
];
const headfulInitialProps = Object.keys(headful.props);
const headfulOdProps = Object.keys(headful.props).map(key => key + OD_SUFFIX);
const restProps = [
    'ods'
];
const headfulProps = headfulInitialProps.concat(headfulOdProps).concat(restProps);

function resolvePropValue (propValue) {
    if (propValue === undefined) throw new Error('propValue must not be undefined');
    if (propValue === null) return null; // In that case, return value as it is.
    if (typeof propValue === 'string') {
        return propValue;
    } else if (Array.isArray(propValue)) {
        if (propValue.length === 0) return null; // Empty array is like passing null!
        for (const item of propValue) {
            if (typeof item === 'string') return item;
        }
    }
    // In any other case, return propValue as it is.
    return propValue;
}

/**
 * Transforms props in order to be processed from headful library.
 */
function getPassedProps (props) {
    return Object.keys(props).reduce((passedProps, propKey) => {
        // Do not include unnecessary to headful props (rest props, OD props).
        if (restProps.some((el) => propKey === el) ||
            headfulOdProps.some((el) => propKey === el)) {
            return passedProps;
        }

        if (props[propKey] !== undefined) {
            passedProps[propKey] = resolvePropValue(props[propKey]);
        }
        return passedProps;
    }, {});
}

/**
 * Helper function that returns the ods value.
 *
 * Priority:
 * - valid ods value from props OR
 * - valid ods value from config OR
 * - ON_DESTROY_STRATEGY_DEFAULT
 */
function getOdsValueFromOdPropsOrConfig (props) {
    if (props === undefined || props === null) return ON_DESTROY_STRATEGY_DEFAULT;

    // Initialize.
    let odsValue = '';

    // Get ods value from props.
    if (typeof props[ON_DESTROY_STRATEGY_KEY] === 'string') odsValue = props[ON_DESTROY_STRATEGY_KEY];

    // Check ods value from props.
    if (odStrategyKeys.some(el => el === odsValue)) return odsValue;

    // Get ods value from config.
    odsValue = HeadMetaOptions.getOption(ON_DESTROY_STRATEGY_KEY);

    // Check ods value from config
    if (odStrategyKeys.some(el => el === odsValue)) return odsValue;

    // ods is missing or is invalid. Return default.
    return ON_DESTROY_STRATEGY_DEFAULT;
}

/**
 * Transforms OD props in order to be processed from headful library.
 */
function getPassedOdProps (props) {
    // Get ods value.
    const odsValue = getOdsValueFromOdPropsOrConfig(props);

    // Check if ODS is disabled and exclude all.
    if (odsValue === ON_DESTROY_STRATEGY_DISABLE_KEY) return {};

    return Object.keys(props).reduce((passedProps, propKey) => {
        // Do not include unnecessary to headful props (rest props, OD props).
        if (restProps.some((el) => propKey === el) ||
            headfulInitialProps.some((el) => propKey === el)) {
            return passedProps;
        }

        // normalize: Remove 'Od' suffix.
        const lastIndex = propKey.lastIndexOf(OD_SUFFIX);
        const normalizedPropKey = propKey.substring(0, lastIndex); // Set value with that key.

        // If propKey exists, keep the value from OD prop.
        // Otherwise act based on OD Strategy.
        if (props[propKey] !== undefined) {
            passedProps[normalizedPropKey] = resolvePropValue(props[propKey]);
        } else {
            if (odsValue === ON_DESTROY_STRATEGY_RESET_KEY) {
                passedProps[normalizedPropKey] = '';
            } else if (odsValue === ON_DESTROY_STRATEGY_CONFIG_OR_EXCLUDE_KEY) {
                // Check if options have default value for that prop or exclude.
                if (HeadMetaOptions.hasValidOption(propKey)) {
                    passedProps[normalizedPropKey] = HeadMetaOptions.getOption(propKey);
                    // eslint-disable-next-line
                } else {} // Exclude (do nothing).
            } else if (odsValue === ON_DESTROY_STRATEGY_CONFIG_OR_RESET_KEY) {
                // Check if options have default value for that prop or reset.
                if (HeadMetaOptions.hasValidOption(propKey)) {
                    passedProps[normalizedPropKey] = HeadMetaOptions.getOption(propKey);
                } else {
                    passedProps[normalizedPropKey] = '';
                }
                // eslint-disable-next-line
            } else {} // Handle somehow...
        }
        return passedProps;
    }, {});
}

const handler = props => headful(getPassedProps(props));
const odHandler = props => headful(getPassedOdProps(props));

export {
    headfulProps,
    handler,
    odHandler
};
