/****************************************************************************************************
 * The base API client.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 12 January 2021
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

import ApiClient from './api-client';

const defaultAxiosInstance = ApiClient.getDefaultAxiosInstance();

const DefaultConfiguration = {};

const BaseApiClient = {
    head (url, configToMerge = {}) {
        const config = Object.assign(cloneDeep(DefaultConfiguration), configToMerge);
        return defaultAxiosInstance.head(url, config);
    },
    get (url, configToMerge = {}) {
        const config = Object.assign(cloneDeep(DefaultConfiguration), configToMerge);
        return defaultAxiosInstance.get(url, config);
    },
    delete (url, configToMerge = {}) {
        const config = Object.assign(cloneDeep(DefaultConfiguration), configToMerge);
        return defaultAxiosInstance.delete(url, config);
    },
    post (url, data = null, configToMerge = {}) {
        const config = Object.assign(cloneDeep(DefaultConfiguration), configToMerge);
        return defaultAxiosInstance.post(url, data, config);
    },
    put (url, data = null, configToMerge = {}) {
        const config = Object.assign(cloneDeep(DefaultConfiguration), configToMerge);
        return defaultAxiosInstance.put(url, data, config);
    }
};

export default BaseApiClient;
