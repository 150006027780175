/****************************************************************************************************
 * User business logic.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 29 January 2021
 ****************************************************************************************************/

import get from 'lodash/get';

import {
    ROLE_USER,
    ROLE_ADMIN,
    ROLE_CLTLR_PREFIX,
    ROLE_CLTLR_SUFFIX,
    ROLE_CLTLR_USER_SUFFIX,
    ROLE_CLTLR_ADMIN_SUFFIX
} from '@/core/constants';

function onlyUnique (value, index, self) {
    return self.indexOf(value) === index;
}

const UserLogic = {
    /**
     * Extracts Tenant IDs from a User's authorities list.
     *
     * IMPORTANT: The existence of an tenantId does not imply access to tenant.
     */
    extractTenantIdList (user) {
        let tenantIdList = [];

        if (user === null) {
            return tenantIdList;
        }

        let authorities = get(user, 'authorities', []);
        if (authorities.length === 0) {
            return tenantIdList;
        }

        tenantIdList = authorities
            .filter((item) => {
                return !!(item.startsWith(ROLE_CLTLR_PREFIX) && item.endsWith(ROLE_CLTLR_SUFFIX));
            })
            .map((item) => {
                let cleaned = item;
                if (cleaned.startsWith(ROLE_CLTLR_PREFIX)) {
                    cleaned = cleaned.replace(ROLE_CLTLR_PREFIX, '');
                }
                if (cleaned.endsWith(ROLE_CLTLR_SUFFIX)) {
                    cleaned = cleaned.replace(ROLE_CLTLR_SUFFIX, '');
                }
                cleaned = cleaned.toLowerCase();
                return cleaned;
            })
            .filter(onlyUnique)
            .sort(function (a, b) {
                if (a < b) { return -1; }
                if (a > b) { return 1; }
                return 0;
            });

        return tenantIdList;
    },

    hasRole (user, role) {
        if (user === null || typeof role !== 'string') {
            return false;
        }

        role = role.trim();
        role = role.toUpperCase();
        if (role.startsWith('ROLE_') === false) {
            role = 'ROLE_' + role;
        }

        return get(user, 'authorities', []).some(function (item) {
            return item === role;
        });
    },

    /** Builds: ROLE_CLTLR_{TENANT_ALIAS}_TENANT */
    getCloutlayerTenantAuthorityFromTenantId (tenantId) {
        if (typeof tenantId !== 'string') throw Error('tenantId must be a string!');
        tenantId = tenantId.trim();
        tenantId = tenantId.toUpperCase();
        return ROLE_CLTLR_PREFIX + tenantId + ROLE_CLTLR_SUFFIX;
    },

    /** Builds: ROLE_CLTLR_{TENANT_ALIAS}_TENANT_USER */
    getCloutlayerTenantUserAuthorityFromTenantId (tenantId) {
        if (typeof tenantId !== 'string') throw Error('tenantId must be a string!');
        tenantId = tenantId.trim();
        tenantId = tenantId.toUpperCase();
        return ROLE_CLTLR_PREFIX + tenantId + ROLE_CLTLR_USER_SUFFIX;
    },

    /** Builds: ROLE_CLTLR_{TENANT_ALIAS}_TENANT_ADMIN */
    getCloutlayerTenantAdminAuthorityFromTenantId (tenantId) {
        if (typeof tenantId !== 'string') throw Error('tenantId must be a string!');
        tenantId = tenantId.trim();
        tenantId = tenantId.toUpperCase();
        return ROLE_CLTLR_PREFIX + tenantId + ROLE_CLTLR_ADMIN_SUFFIX;
    },

    getAccessLevels (user, tenantId) {
        const tenantMembershipRole = UserLogic.getCloutlayerTenantAuthorityFromTenantId(tenantId);
        const tenantUserRole = UserLogic.getCloutlayerTenantUserAuthorityFromTenantId(tenantId);
        const tenantAdminRole = UserLogic.getCloutlayerTenantAdminAuthorityFromTenantId(tenantId);

        const isUser = UserLogic.hasRole(user, ROLE_USER);
        const isAdmin = UserLogic.hasRole(user, ROLE_ADMIN);
        const hasTenantMembership = UserLogic.hasRole(user, tenantMembershipRole);
        const isTenantUser = UserLogic.hasRole(user, tenantUserRole);
        const isTenantAdmin = UserLogic.hasRole(user, tenantAdminRole);

        return {
            hasTenantUserAccessLevel: isAdmin === true || (isUser === true && hasTenantMembership === true && isTenantUser === true),
            hasTenantAdminAccessLevel: isAdmin === true || (isUser === true && hasTenantMembership === true && isTenantUser === true && isTenantAdmin === true)
        };
    }
};

export default UserLogic;
