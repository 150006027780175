import format from 'date-fns/format';

const DEFAULT_FORMAT = 'd MMM Y HH:mm';

/**
 * A filter that accepts a string or a Date and returns a formatted date as string.
 *
 * @author Dimitris Gkoulis
 * @createdAt 13 April 2021
 */
export default function dateFormat (value, formatLiteral = DEFAULT_FORMAT) {
    if (typeof formatLiteral !== 'string') {
        formatLiteral = DEFAULT_FORMAT;
    }
    if (typeof value === 'string') {
        return format(new Date(value), formatLiteral);
    } else {
        // @future validate.
        return format(value, formatLiteral);
    }
}
