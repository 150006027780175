<template>
    <div class="absolute w-1/2 z-40 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <p class="text-gray-500 font-semibold text-xl text-center">
            <slot>{{ $t('common_no_results') }}</slot>
        </p>
    </div>
    <!--
    <div class="overflow-hidden rounded-lg">
        <div class="px-4 py-16 text-center">
            <p class="text-gray-500 font-semibold text-xl">
                <slot>{{ $t('No results found!') }}</slot>
            </p>
        </div>
    </div>
    -->
</template>

<script>
/**
 * Common empty state.
 *
 * @author Dimitris Gkoulis
 * @createdAt 13 January 2021
 * @lastModifiedAt 20 February 2021
 */
export default {
    name: 'CommonEmptyState'
};
</script>
