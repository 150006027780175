/****************************************************************************************************
 * v-click-outside directive.
 *
 * Not working with multiple pop-overs.
 * Implemented with no SSR in mind.
 * Original: https://github.com/vue-bulma/click-outside/blob/master/index.js
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 28 February 2021
 ****************************************************************************************************/

function validate (binding) {
    if (typeof binding.value !== 'function') {
        console.warn('[Vue-click-outside:] provided expression', binding.expression, 'is not a function.');
        return false;
    }
    return true;
}

function getClickHandler () {
    return 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
}

const ClickOutside = {
    beforeMount: function (el, binding) {
        if (!validate(binding)) {
            return;
        }

        // Define Handler and cache it on the element.
        function handler (e) {
            if (!binding.instance) {
                return;
            }
            if (el.contains(e.target)) {
                return;
            }
            el.__vueClickOutside__.callback(e);
        }

        // add Event Listeners.
        el.__vueClickOutside__ = {
            handler: handler,
            callback: binding.value
        };

        setTimeout(() => {
            document.addEventListener(getClickHandler(), handler);
        }, 50);
    },
    updated: function (el, binding) {
        if (validate(binding)) el.__vueClickOutside__.callback = binding.value;
    },
    unmounted: function (el) {
        el.__vueClickOutside__ && document.removeEventListener(getClickHandler(), el.__vueClickOutside__.handler);
        delete el.__vueClickOutside__;
    }
};

export default ClickOutside;
