<template>
    <label
        v-if="!!label"
        :for="name"
        class="VvLabel"
    >
        {{ label }} <span class="text-xs font-normal text-gray-400" v-if="optional">{{ optionalText }}</span>
    </label>
    <div :class="{ 'mt-1': !!label }">
        <Field
            :name="name"
            :label="label"
            :value="modelValue"
            @input="onInput"
            :validate-on-blur="validateOnBlur"
            :validate-on-change="validateOnChange"
            :validate-on-input="validateOnInput"
            :validate-on-model-update="validateOnModelUpdate"
            v-slot="{ field }"
        >
            <select
                v-bind="field"
                :id="name"
                :tabindex="tabIndex"
                class="VvSelect--Default"
            >
                <slot>
                    <option
                        v-for="option in options"
                        :key="option.value"
                        :value="option.value"
                    >
                        {{ option.label }}
                    </option>
                </slot>
            </select>
        </Field>
    </div>
    <div class="mt-2">
        <ErrorMessage
            :name="name"
            v-slot="{ message }"
        >
            <p class="VvErrorMessage__Text">{{ message }}</p>
        </ErrorMessage>
    </div>
    <div class="mt-2">
        <p v-if="!!helpText" class="VvHelpText--Default">{{ helpText }}</p>
    </div>
</template>
<script>
import { Field, ErrorMessage } from 'vee-validate';
import { I18nPlugin } from '@/core/plugins';

const i18n = I18nPlugin.getDefaultI18nInstance();

/**
 * Select pattern based on VeeValidate.
 *
 * @author Dimitris Gkoulis
 * @createdAt 19 January 2021
 * @lastModifiedAt 19 February 2021
 */
export default {
    name: 'VvSelect',
    inheritAttrs: false,
    components: {
        Field,
        ErrorMessage
    },
    emits: [
        'update:modelValue',
        'update-batch'
    ],
    props: {
        name: {
            type: String,
            required: true
        },
        label: String,
        optional: Boolean,
        optionalText: {
            type: String,
            default: function () {
                return i18n.global.t('Optional');
            }
        },
        helpText: String,
        options: Array,
        modelValue: null,
        tabIndex: Number,
        // FormVueLate + VeeValidate specifics.
        validateOnBlur: {
            type: Boolean,
            default: true
        },
        validateOnChange: {
            type: Boolean,
            default: true
        },
        validateOnInput: {
            type: Boolean,
            default: true
        },
        validateOnModelUpdate: {
            type: Boolean,
            default: true
        },
        validation: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        onInput (event) {
            this.$emit('update:modelValue', event.target.value);
        }
    }
};
</script>

<style>
.VvSelect--Default {
    @apply mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md shadow-sm;
    @apply text-lg;
}
</style>
