/****************************************************************************************************
 * MS Storage "StoredObject" resource API Client.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 21 February 201
 * @lastModifiedAt Saturday 11 December 2021
 ****************************************************************************************************/

import ApiUrlProvider from '../api-url-provider';
import BaseApiClient from '../base-api-client';

const StorageStoredObjectBasicApiClient = {
    uploadContentStoredObject (file, fileName = null) {
        let formData = new FormData();
        if (typeof fileName !== 'string') {
            fileName = file.name;
        }
        formData.append('file', file);
        const url = ApiUrlProvider.kernelVersionedApi('stored-objects/content-uploads');
        return BaseApiClient.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
                // ...formData.getHeaders()
            },
            withCredentials: true,
            crossDomain: true
        });
    },
    getStoredObjectById (id) {
        if (typeof id !== 'string') {
            return Promise.reject(new Error('id is null or undefined'));
        }
        if (id.trim() === '') {
            return Promise.reject(new Error('id is blank'));
        }
        // @future : BUG FIX: If ID is not string of if it's blank, all StoredObject entities will be returned (instead of 404)!
        const url = ApiUrlProvider.kernelVersionedApi('stored-objects/' + id);
        return BaseApiClient.get(url);
    },
    getStoredObjectsByIds (idList) {
        const params = idList.map(i => `id=${i}`).join('&');
        const url = ApiUrlProvider.kernelVersionedApi('stored-objects/bulk-lookups?' + params);
        return BaseApiClient.get(url);
    },
    deleteStoredObject (id) {
        const url = ApiUrlProvider.kernelVersionedApi('stored-objects/' + id);
        return BaseApiClient.delete(url);
    },
    getAllStoredObjects (title = null, page = 0, size = 10, sortField = 'id', sortDirection = 'desc') {
        const url = ApiUrlProvider.kernelVersionedApi('stored-objects');
        return BaseApiClient.get(url, {
            params: {
                title: title,
                page: page,
                size: size,
                sort: sortField + ',' + sortDirection
            }
        });
    }
};

export default StorageStoredObjectBasicApiClient;
