/****************************************************************************************************
 * Pagination Utilities.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 13 April 2021
 * @lastModifiedAt 14 April 2021
 ****************************************************************************************************/

const PaginationUtils = {};

const ensureCurrentPage = (page, lastPage) => {
    let result = 1;
    if (page && page > 0) {
        if (page <= lastPage) {
            result = page;
        } else {
            result = lastPage;
        }
    }
    return result;
};

// The number of pages.
const getPageCount = (totalCount, pageSize) => {
    if (totalCount === Infinity) {
        return Infinity;
    }
    return Math.max(1, Math.ceil(totalCount / pageSize));
};

const getPagesArray = pageCount => {
    if (pageCount === Infinity) {
        return [];
    }
    return Array.from({ length: pageCount }, (val, key) => key + 1);
};

const getFirstItem = (currentPage, pageSize) => 1 + (currentPage - 1) * pageSize;

PaginationUtils.getPaginationDetails = function (params) {
    let {
        currentPage, // The number of current page starting from 1.
        pageSize, // The size of each page.
        count, // Number of items in current page.
        totalCount // Total number of items.
    } = params;

    const pageCount = getPageCount(totalCount, pageSize);
    currentPage = ensureCurrentPage(currentPage, pageCount); // Ensure that currentPage has the correct value.
    // const actualCurrentPage = currentPage - 1;
    const pagesArray = getPagesArray(pageCount);
    const firstItem = getFirstItem(currentPage, pageSize);
    const hasNotPrevious = currentPage === 1;
    const hasNotNext = currentPage === pageCount;
    const start = Math.min(firstItem, totalCount);
    const end = Math.min(firstItem + Math.min(parseInt(pageSize, 10), count) - 1, totalCount);

    const rKey = `rKey-${new Date().getTime()}-${Math.floor(Math.random() * 10)}`;

    return {
        currentPage,
        // actualCurrentPage,
        pageSize,
        count,
        totalCount,

        firstPage: 1,
        lastPage: pageCount,

        pageCount,
        pagesArray,
        firstItem,
        hasNotPrevious,
        hasNotNext,
        start,
        end,

        rKey
    };
};

export default PaginationUtils;
