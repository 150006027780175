<template>
    <div
        :class="wrapperClass"
    >
        <div class="flex">
            <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="ml-3">
                <div class="space-y-0.5">
                    <h3 class="text-sm font-medium text-red-800">{{ title }}</h3>
                    <p class="text-sm font-medium text-red-700">{{ subtitle }}</p>
                </div>
                <div
                    v-if="enableTechnicalDetails && errorInstance"
                    class="mt-2"
                >
                    <p
                        @click="switchDisplayDetails"
                        class="text-xs cursor-pointer text-red-400"
                    >
                        {{ $t('sserror_details') }}
                    </p>
                </div>
                <div
                    v-if="enableTechnicalDetails && errorInstance && displayDetails"
                    class="mt-2 text-xs text-gray-700"
                >
                    <div class="space-y-1">
                        <p v-if="errorInstance.message">M: {{ errorInstance.message }}</p>
                        <p v-if="errorInstance.dataType">DTYPE: {{ errorInstance.dataType }}</p>
                        <p v-if="errorInstance.dataMessage">DTITLE: {{ errorInstance.dataTitle }}</p>
                        <p v-if="errorInstance.dataMessage">DMESSAGE: {{ errorInstance.dataMessage }}</p>
                        <p v-if="errorInstance.dataDetail">DDETAIL: {{ errorInstance.dataDetail }}</p>
                        <p v-if="errorInstance.dataService">DSERVICE: {{ errorInstance.dataService }}</p>
                        <p v-if="errorInstance.dataEntity">DENTITY: {{ errorInstance.dataEntity }}</p>
                        <p v-if="errorInstance.httpStatus">HS: {{ errorInstance.httpStatus }}</p>
                        <p v-if="errorInstance.dataPath">P: {{ errorInstance.dataPath }}</p>
                        <p v-if="errorInstance.dataTimestamp">T: {{ errorInstance.dataTimestamp }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, toRefs } from 'vue';
import { I18nPlugin } from '@/core/plugins';

const i18n = I18nPlugin.getDefaultI18nInstance();

/**
 * Common error state.
 *
 * @author Dimitris Gkoulis
 * @createdAt 19 July 2020
 * @lastModifiedAt 4 March 2021
 */
export default {
    name: 'CommonErrorState',
    props: {
        error: {
            required: false
        },
        enableTechnicalDetails: {
            type: Boolean,
            required: false,
            default: true
        },
        wrapperClass: {
            type: String,
            required: false,
            default: 'bg-red-50 p-4'
        }
    },
    setup (props) {
        const { error } = toRefs(props);

        const DEFAULT_TITLE =  i18n.global.t('sserror_title_1');
        const DEFAULT_SUBTITLE =  i18n.global.t('sserror_subtitle_1');

        const title = computed(() => {
            let titleTemp = DEFAULT_TITLE;
            if (error && error.value) {
                titleTemp = error.value.dataTitle;
            }
            if (typeof titleTemp !== 'string') {
                titleTemp = DEFAULT_TITLE;
            }
            if (titleTemp.trim() === '') {
                titleTemp = DEFAULT_TITLE;
            }
            return titleTemp;
        });
        const subtitle = computed(() => {
            let subtitleTemp = DEFAULT_SUBTITLE;
            return subtitleTemp;
        });

        const displayDetails = ref(false);
        const switchDisplayDetails = () => {
            displayDetails.value = !displayDetails.value;
        };

        return {
            errorInstance: error,
            title,
            subtitle,
            displayDetails,
            switchDisplayDetails
        };
    }
};
</script>
