/****************************************************************************************************
 * PPA (Polyglot Persistence API) business logic and utilities.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt Saturday 11 December 2021
 * @lastModifiedAt never
 ****************************************************************************************************/

const PolyglotPersistenceAPILogic = {};

PolyglotPersistenceAPILogic.getEntitySpaceNameForWebsitePages = function (websiteId) {
    return `website_pageData_${websiteId}`;
};

PolyglotPersistenceAPILogic.getEntitySpaceNameForContentGroup = function (contentGroupId) {
    return `contentGroup_${contentGroupId}`;
};

export default PolyglotPersistenceAPILogic;
